import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchHeaderNotificationsData, fetchUserData } from "../../redux";

// Import Services
import auth from "../../services/authService";

// Import Bootstrap Components
import { Dropdown } from "react-bootstrap";

// Components
import LNAvatar from "../common/LNAvatar/LNAvatar";

// Import Images
import LenmeEnterpriseLogo from "../../img/assets/lenme_enterprise.svg";
import LenmeLogo from "../../img/assets/logo.svg";
import NotificationIcon from "../../img/icons/notification-icon.png";
import MenuIcon from "../../img/icons/black/burger-menu.svg";
import InfoIcon from "../../img/icons/info-icon.svg";
import UserLoadingBlock from "../../img/spinners/blocks/user header.svg";
import NotificationLoadingBlock from "../../img/spinners/blocks/notification header.svg";

// Import CSS
import "./header.css";

function Header({ handleOpen }) {
	const [account] = useState(auth.getCurrentAccount());
	const userData = useSelector((state) => state.user.userData);
	const { user } = userData;
	const notificationsData = useSelector((state) => state.notifications.headerNotificationsData);
	const { notifications } = notificationsData;
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		// Get User
		setTimeout(() => {
			dispatch(fetchUserData());
		}, 2000);
	}, [dispatch]);

	// Helper Methods
	const checkIfSignupProcess = () => {
		if (location.pathname.startsWith("/signup-process")) return true;
		return false;
	};

	// Render
	return (
		<div className="header d-flex align-items-center pl-3 pl-md-5">
			<div className="left-logo">
				<img src={LenmeEnterpriseLogo} alt="Lenme Enterprise" className="header-logo" />
			</div>

			{account && !checkIfSignupProcess() && (
				<button onClick={handleOpen} className="menu-btn">
					<img src={MenuIcon} alt="Menu" className="d-none d-sm-block" />
					<img src={LenmeLogo} alt="Menu" className="d-sm-none" />
				</button>
			)}

			<div className="mid-logo d-none d-sm-block d-md-none">
				<img src={LenmeLogo} alt="Lenme" />
			</div>

			{account && !checkIfSignupProcess() && (
				<div className="d-flex ml-auto">
					<Dropdown>
						<Dropdown.Toggle bsPrefix="d-none d-sm-flex notification-header-btn">
							<div className="header-btn" onClick={() => dispatch(fetchHeaderNotificationsData())}>
								<img src={NotificationIcon} alt="Notification" />
							</div>
						</Dropdown.Toggle>

						<Dropdown.Menu bsPrefix="dropdown-menu notification-popup p-4">
							{notificationsData.loading && (
								<div>
									<img src={NotificationLoadingBlock} alt="Loading..." />
								</div>
							)}

							{!notificationsData.loading && notifications.results?.length === 0 && (
								<div className="zero-state text-center">
									<div>No notifications</div>
									<div>You’ve got no item to catch up.</div>
								</div>
							)}

							{!notificationsData.loading && notifications.results?.length !== 0 && (
								<>
									{notifications.results.map((item) => (
										<Dropdown.Item key={item.id} as="div" bsPrefix="single-item">
											<Link to="/user/notification">
												<div className="d-flex py-3 border-bottom">
													<div className="mr-4">
														<img src={InfoIcon} alt="Not Available" />
													</div>
													<div>{item.content}</div>
												</div>
											</Link>
										</Dropdown.Item>
									))}

									<Dropdown.Item as="div" bsPrefix="see-all">
										<Link to="/user/notification">View All</Link>
									</Dropdown.Item>
								</>
							)}
						</Dropdown.Menu>
					</Dropdown>

					{userData.loading && (
						<div className="d-none d-md-inline-block">
							<div className="header-btn d-flex align-items-center">
								<img src={UserLoadingBlock} alt="Loading..." />
							</div>
						</div>
					)}

					{!userData.loading && (
						<NavLink to="/user/profile" className="header-btn user-header-btn pl-4 pr-3 pr-md-5">
							<LNAvatar data={[{ icon: user.avatar, color: 4 }]} small={true} />
							<span className="d-none d-md-inline-block">
								{user.first_name} {user.last_name}
							</span>
						</NavLink>
					)}
				</div>
			)}
		</div>
	);
}

export default Header;
