import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

// Import Services
import auth from "../../services/authService";

// Import Utils

// Import Components

// Import Images

// Import CSS
import "./homePage.css";

class HomePage extends Component {
	state = {
		loading: true,
	};

	// Get Account
	populateAccount() {
		const account = auth.getCurrentAccount();
		this.setState({ account });
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		setTimeout(() => {
			this.populateAccount();
			// End loading
			this.handleLoading();	
		}, 2000);
		
	}

	// When Update
	componentDidUpdate(prevProps, prevState) {}

	// When Unmount
	componentWillUnmount() {}

	// Helper Methods
	handleMethod = () => {};

	render() {
		const { loading, account } = this.state;

		// Check if account
		if (account) return <Navigate to="/user" />

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Navigate to login page
		return <Navigate to="/login" />
	}
}

// Prop Types
HomePage.propTypes = {
	prop: PropTypes.any,
};

export default HomePage;
