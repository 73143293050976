import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchLoanRequests, fetchFilteredLoanRequests } from "../../redux";
import queryString from "query-string";
import geo2zip from "geo2zip";

// Bootstrap Components
import { Modal } from "react-bootstrap";

// Components
import LNCheckBoxWithBullets from "../common/LNCheckBoxWithBullets/LNCheckBoxWithBullets";
import LNCheckBox from "../common/LNCheckBox/LNCheckBox"
import LNInput from "../common/LNInput/LNInput";
import { State }  from 'country-state-city';
import Select from 'react-select';

// Images
import FilterBackArrow from "../../img/icons/black/filter-back-arrow.svg";
import LenmeScoreLegend from "../../img/assets/legendLenmeScore.svg";
import Info from "../../img/icons/muted/info.svg";
// CSS
import "./filter.css";

class Filter extends Component {
	
	state = {
		loading: true,
		usZipCodes:[],
		data: {
			lenmo_color_0: "",
			lenmo_color_1: "",
			lenmo_color_2: "",
			lenmo_color_3: "",
			lenmo_color_any: "",
			loan_amount_0: "",
			loan_amount_1: "",
			credit_score_0: "",
			credit_score_1: "",
			loan_terms_0: "",
			loan_terms_1: "",
			zip_code: null,
			state:null,
			completed_loans_count_0: "",
			completed_loans_count_1: "",
			previous_borrowers: false,
			invited_borrowers: false,
			nearby_zip_code: false,
		},
		modal: {
			lenmeScore: {
				visible: false,
			},
		},
	};

	
	handleStateChange = (state) => {
		let newVar = ""
			state.map((e,i)=>{
			if(i === state?.length){
				newVar+=`${e.isoCode}`
			}else{
				newVar+=`${e.isoCode},`
			}
		})
		const data = { ...this.state.data, ...{state:newVar} };
		this.setState({data})
	  };

	  handleZipCodeChange = (zip) => {
		let newVar = ""
			zip.map((e,i)=>{
			if(i === zip?.length){
				newVar+=`${e.value}`
			}else{
				newVar+=`${e.value},`
			}
		})
		const data = { ...this.state.data, ...{zip_code:newVar} };
		this.setState({data})
	  };

	// Helper Methods
	validate = () => {
		const errors = {};
		const { data } = this.state;

		for (let item of Object.values(data)) {
			if (item !== "") {
				errors["message"] = "Filter data is not empty.";
				break;
			}
		}

		return Object.keys(errors)?.length !== 0 ? null : errors;
	};

	handleChange = ({ currentTarget: input }) => {
		const data = { ...this.state.data };
		data[input.name] = input.value;
		this.setState({ data });
	};

	handleCheckboxChange = ({ currentTarget: input }) => {
		const data = { ...this.state.data };

		if (input.checked) data[input.name] = input.value;
		if (!input.checked) data[input.name] = "";

		this.setState({ data });
	};

	handleBoleanCheckboxChange = ({ currentTarget: input }) => {
		const data = { ...this.state.data };

		if (input.checked) data[input.name] = input.checked;
		if (!input.checked) data[input.name] = false;

		this.setState({ data });
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) return;

		this.doSubmit();
	};

	doSubmit = async () => {
		const data = { ...this.state.data };

		for (let item of Object.keys(data)) {
			if (data[item] === "") {
				delete data[item];
			}
		}

		let query = "?" + queryString.stringify(data);
		query = query.replace(/lenmo_color_[0-9]/gim, "lenmo_color");
		console.log(query);

		this.props.fetchFilteredLoanRequests(query);

		// Hide filter for mobile
		this.props.handleShowFilter();
	};

	handleReset = async (action) => {
		const data = {
			lenmo_color_0: "",
			lenmo_color_1: "",
			lenmo_color_2: "",
			lenmo_color_3: "",
			lenmo_color_any: "",
			loan_amount_0: "",
			loan_amount_1: "",
			credit_score_0: "",
			credit_score_1: "",
			loan_terms_0: "",
			loan_terms_1: "",
			zip_code: "",	
			state:null,
			completed_loans_count_0: "",
			completed_loans_count_1: "",
			previous_borrowers: false,
			invited_borrowers: false,
			nearby_zip_code: false,
		
		};

		this.setState({ data });
		// if(action == "reload"){
		// 	window.location.replace("/user/dashboard")
		// }else{
		// 	window.location.replace("/user/dashboard/liked")
		// }
		await this.props.fetchLoanRequests();
	
		// Hide filter for mobile
		this.props.handleShowFilter();
	};

	handleOpenModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = true;
		this.setState({ modal });
	};

	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		this.setState({ modal });
	};

	getZipsCodes = async () => {
		const location = {
			latitude: 34.659698,
			longitude: -88.242903,
		  };
		  
		 let zipCodes = await geo2zip(location, { limit: 3000 });
		 const keyedZipCodes = zipCodes.map((zipCode, index) => ({ label: zipCode, value: zipCode }));
		 console.log("keyedZipCodes",keyedZipCodes);
		 this.setState({usZipCodes:keyedZipCodes})

	}

	componentDidMount() {
		document.addEventListener("resetFilters", ()=>this.handleReset("reload"));
		document.addEventListener("resetFiltersLiked", ()=>this.handleReset("replace"));
	  }
	
	componentWillUnmount() {
		document.addEventListener("resetFilters", ()=>this.handleReset("reload"));
		document.addEventListener("resetFiltersLiked", ()=>this.handleReset("replace"));
	  }

	componentDidUpdate(prevProps) {
		const data = {
			lenmo_color_0: "",
			lenmo_color_1: "",
			lenmo_color_2: "",
			lenmo_color_3: "",
			lenmo_color_any: "",
			loan_amount_0: "",
			loan_amount_1: "",
			credit_score_0: "",
			credit_score_1: "",	
			loan_terms_0: "",
			loan_terms_1: "",
			zip_code: "",
			state:null,
			completed_loans_count_0: "",
			completed_loans_count_1: "",
			previous_borrowers: false,
			invited_borrowers: false,
			nearby_zip_code: false,

		};
	if (this.props.refresh !== prevProps.refresh && this.props.refresh) {
		// Reset state to initial values here
		this.setState({ data });
	}
	}

	render() {

		const { data } = this.state;

		const { state } = this.state;

		const { filterLoanRequests, handleShowFilter } = this.props;
		
		const CountryStates = State.getStatesOfCountry("US")
		
		const USZips = require('us-zips/array')

		CountryStates.forEach(function(obj) {
			obj.label = obj.name;
			obj.value = obj.isoCode;
		  });

		USZips.forEach(function(obj) {
			obj.label = obj.zipCode;
			obj.value = obj.zipCode;
		  });
		 		
		// Checkboxes data
		const checkboxes = [
			// { label: "Any", name: "lenmo_color_any", value: "any", checked: false },
			{ label: "High risk", name: "lenmo_color_0", value: "0", checked: false },
			{ label: "Medium risk", name: "lenmo_color_1", value: "1", checked: false },
			{ label: "Low risk", name: "lenmo_color_2", value: "2", checked: false },
			{ label: "Backed by Crypto", name: "lenmo_color_3", value: "4", checked: false },
		];

		const bolean_checkboxes = [
			{ label: "Previous borrowers",name: "previous_borrowers", value: 0, checked: false },
			{ label: "Store referred", name: "invited_borrowers", value: 1, checked: false },
			{ label: "Nearby zip-code", name: "nearby_zip_code", value: 2, checked: false },
			
		];

		// Render
		return (
			<>
				<form onSubmit={this.handleSubmit}>
					<div className="card filter-container">
						<div className="back-link mb-5" onClick={handleShowFilter}>
							<img src={FilterBackArrow} alt="<--" /> Back to dashboard
						</div>

						<div className="filter-title pb-3 mb-3 font-weight-bold">Filter</div>

						<div className="mb-4">
							<div className="font-weight-bold small mb-2">
								Risk profile
								<img
									className="pointer p-1"
									onClick={() => this.handleOpenModal("lenmeScore")}
									src={Info}
									alt="Info"
									width="24"
								/>
							</div>
							<div>
								{checkboxes.map((item) => (
									<div key={item.value} className="mb-2">
										<LNCheckBoxWithBullets
											label={item.label}
											name={item.name}
											value={item.value}
											checked={data[item.name] === "" ? false : true}
											onChange={this.handleCheckboxChange}
										/>
									</div>
								))}
							</div>
						</div>

						<div className="mb-4">
							<div className="font-weight-bold small mb-2">Loan amount</div>
							<div className="filter-row">
								<LNInput
									placeholder="$150"
									name="loan_amount_0"
									value={data.loan_amount_0}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>

								<div className="px-2">-</div>

								<LNInput
									placeholder="$3000"
									name="loan_amount_1"
									value={data.loan_amount_1}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>
							</div>
						</div>

						<div className="mb-4">
							<div className="font-weight-bold small mb-2">Credit score</div>
							<div className="filter-row">
								<LNInput
									placeholder="350"
									name="credit_score_0"
									value={data.credit_score_0}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>

								<div className="px-2">-</div>

								<LNInput
									placeholder="850"
									name="credit_score_1"
									value={data.credit_score_1}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>
							</div>
						</div>

						<div className="mb-4">
							<div className="font-weight-bold small mb-2">
								Pay period <span className="font-weight-normal">(month/s)</span>
							</div>
							<div className="filter-row">
								<LNInput
									placeholder="1"
									name="loan_terms_0"
									value={data.loan_terms_0}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>

								<div className="px-2">-</div>

								<LNInput
									placeholder="12"
									name="loan_terms_1"
									value={data.loan_terms_1}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>
							</div>
						</div>
						{/* <div className="mb-4">

						<div className="font-weight-bold small mb-2">
							Zipcode 
						</div>
						<LNInput
							placeholder="94103"
							name="zip_code"
							value={data.zip_code}
							onChange={this.handleChange}
							removablePlaceholder={true}
							autoComplete="off"
						/>
						</div> */}
						<div className="mb-4">
							<div className="font-weight-bold small mb-2">
								Zipcode 
							</div>
							<Select
								onInputChange={(res)=>{
									if(res?.length > 2){
										let filteredArray = USZips.filter((e) => e.zipCode.includes(res))
										console.log("USZips",USZips.filter((e) => e.zipCode.includes(res)));
										this.setState({usZipCodes:filteredArray})
									}
								}}
								isMulti
								isSearchable
								value={state}
								onChange={this.handleZipCodeChange}
								isClearable
								placeholder="94103"
								backspaceRemovesValue
								options={this.state.usZipCodes}
							/>
						</div>
						<div className="mb-4">
							<div className="font-weight-bold small mb-2">
								State 
							</div>
							<Select
								isMulti
								isSearchable
								value={state}
								onChange={this.handleStateChange}
								isClearable
								placeholder="California"
								backspaceRemovesValue
								options={CountryStates}
							/>
						</div>

						<div className="mb-4">
							<div className="font-weight-bold small mb-2">
								Loan history 
							</div>
							<div className="filter-row">
								<LNInput
									placeholder="0"
									name="completed_loans_count_0"
									value={data.completed_loans_count_0}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>

								<div className="px-2">-</div>

								<LNInput
									placeholder="50"
									name="completed_loans_count_1"
									value={data.completed_loans_count_1}
									onChange={this.handleChange}
									removablePlaceholder={true}
									autoComplete="off"
								/>
							</div>
						</div>
						<div>
							{bolean_checkboxes.map((item) => (
								<div key={item.value} className="mb-2">
									<LNCheckBox
										label={item.label}
										name={item.name}
										value={item.value}
										checked={data[item.name] === "" ? false : true}
										onChange={this.handleBoleanCheckboxChange}
									/>
								</div>
							))}
						</div>
						<div className="d-sm-flex">
							<button
								onClick={()=>this.handleReset("reload")}
								type="reset"
								className="btn btn-outline-black btn-block mr-2 mt-2">
								Reset
							</button>

							{!filterLoanRequests.loading && (
								<button
									onClick={this.handleSubmit}
									className="btn btn-black btn-block"
									disabled={this.validate()}>
									Apply
								</button>
							)}

							{filterLoanRequests.loading && (
								<button type="button" className="btn btn-black btn-block" disabled>
									Apply
								</button>
							)}
						</div>
					</div>
				</form>

				<Modal
					show={this.state.modal.lenmeScore.visible}
					onHide={() => this.handleCloseModal("lenmeScore")}
					dialogClassName="general-modal lenme-score-modal">
					<Modal.Header>
						<div></div>
						<button onClick={() => this.handleCloseModal("lenmeScore")} className="btn">
							Close
						</button>
					</Modal.Header>

					<Modal.Body>
						<div className="modal-title">Lenme Score</div>
						<div className="modal-text">
							A borrower's Lenme Score is an indication of their risk profile. Lenme Scores are a
							combination of the borrower's credit report information, affordability of the borrower to
							pay the loan back (based on their annual income and the monthly installments), and the
							history of their behavior using Lenme. The Lenme score is a rating out of 100. The higher
							the score, the lower the risk.
						</div>
						<div className="lenme-score-legend">
							<img src={LenmeScoreLegend} alt="Not found." />
						</div>
					</Modal.Body>

					<Modal.Footer>
						<button onClick={() => this.handleCloseModal("lenmeScore")} className="btn btn-black btn-lg">
							Got it
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

// Prop Types
Filter.propTypes = {
	handleShowFilter: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		filterLoanRequests: state.dashboard.filterLoanRequests,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchLoanRequests: () => dispatch(fetchLoanRequests()),
		fetchFilteredLoanRequests: (query) => dispatch(fetchFilteredLoanRequests(query)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
