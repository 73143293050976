import React, { Component, forwardRef } from "react";
import { connect } from "react-redux";
import {
	fetchYodleeDataAggregation,
	fetchBorrowerCompletedLoansHistory,
	fetchFavoriteLoan,
	fetchUnfavoriteLoan,
	fetchDismissLoan,
	fetchOfferLoan,
	updateOfferLoans,
	fetchFavLoanRequests,
	fetchLoanRequestInfoData
} from "../../redux";
import PropTypes from "prop-types";

// Utils
import { images } from "../../utils/handleDynamicImages";
import { handleNumberWithCommas } from "../../utils/handleNumberWithCommas";

// Components
import LNAvatar from "../common/LNAvatar/LNAvatar";
import LNRequestCardAvatar from "../common/LNRequestAvatarCard/LNRequestCard"
import InfoCard from "../infoCard/infoCard";
import InfoCardDetails from "../infoCardDetails/infoCardDetails";
import RequestsHistory from "../requestsHistory/requestsHistory";
import LNRangeSlider from "../common/LNRangeSlider/LNRangeSlider";
import InfoIcon from "../common/infoIcon/infoIcon";
import PopupHeader from "../common/popupHeader/popupHeader";
import LNModal from "../common/LNModal/LNModal";
import LNRadioInput from "../common/LNRadioInput/LNRadioInput";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";

// Images
import StarGrey from "../../img/assets/star_grey.svg";
import StarYellow from "../../img/assets/star_yellow.svg";
import VantageScoreLegend from "../../img/assets/legendVantageScore.svg";
import PaymentHistoryLegend from "../../img/assets/legendPaymentHistory.svg";
import LenmeScoreLegend from "../../img/assets/legendLenmeScore.svg";
import LenmePredict from "../../img/static/group_455.svg";
import ConfidenceLevel from "../../img/static/group_485.svg";
import PaymentObtions from "../../img/static/group_484.svg";
import CreditUtilizationLegend from "../../img/assets/legendCreditUtilization.svg";
import DerogatoryMarksLegend from "../../img/assets/legendDerogatoryMarks.svg";
import HardInquiriesLegend from "../../img/assets/legendHardInquiries.svg";
import TotalAccountsLegend from "../../img/assets/legendTotalAccounts.svg";
import FinancialHealthLegend from "../../img/assets/legendFinancialHealth.svg";
import CircleExpense from "../../img/assets/circle-expense.svg";
import CircleIncome from "../../img/assets/circle-income.svg";
import LoadingOffer from "../../img/spinners/offer_loading.gif";
import LikeLoading from "../../img/spinners/like_loading.gif";
import AppleIcon from "../../img/icons/apple-pay.svg";
import GoogleIcon from "../../img/icons/google-pay.svg";
import LockIcon from "../../img/icons/muted/lock.svg";

// CSS
import "./requestCard.css";
import { PostLoanRequestView } from "../../services/loanService";
import store from "../../redux/store";

class RequestCard extends Component {
	state = {
		modal: {
			requestPopUp: {
				visible: false,
			},
		},
		visible: {
			requestData: true,
			financialHealth: false,
			lenmeScore: false,
			vantageScore: false,
			paymentHistory: false,
			creditUtilization: false,
			derogatoryMarks: false,
			hardInquiries: false,
			annualIncome: false,
			totalAccounts: false,
			lenmeHistory: false,
			requestComplete: false,
			lenmePredict: false,
			subscribeNowAndSave: false,
			confidenceLevel: false,
			paymentOptions: false,
			backedByCrypto: false,
		},
		creditScoreIcon: null,
		expected_earning: 0.0,
		offered_interest: 3,
		dataAggregation: {},
		borrowerHistory: {},
		data: {},
		makeOffer: {
			loading: false,
		},
		favoriteLoading: false,
	};

	// Populate Card Data
	populateCardData() {
		const { data } = this.props;
		this.setState({ data });
	}

	handleTriggerRefreshFavLoans = () => {
        const resetEvent = new CustomEvent("refreshFavLoans");
        document.dispatchEvent(resetEvent);
    };

	// When Mount
	componentDidMount() {

		// console.log('reuqestCard_MOUNTED')
		// Populate Card Data
		this.populateCardData();

		// Set Corresponding credit score icon
		this.setCreditScoreIcon(this.props.data.borrower.credit_score);

		// Calculate and set Expected Earning
		this.setExpectedEarning(this.props.data.loan_amount, this.state.offered_interest, this.props.data.loan_terms);

		console.log('this.state.data ', this.props.data?.borrower_data_aggs);
	}

	// When Update
	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			// Populate Card Data
			this.populateCardData();

			// Set Corresponding credit score icon
			this.setCreditScoreIcon(this.state.data.borrower.credit_score);

			// Calculate and set Expected Earning
			this.setExpectedEarning(
				this.state.data.loan_amount,
				this.state.offered_interest,
				this.state.data.loan_terms
			);
		}
	}

	// Helper Methods
	async handleOpenModal(name) {

		// console.log('requestCard_handleOpenModal')
		if (name === "requestPopUp") {
			this.setState({ makeOffer: { loading: true } });

			await this.props.fetchYodleeDataAggregation();
			await this.props.fetchBorrowerCompletedLoansHistory();

			this.setState({ dataAggregation: this.props.yodleeDataAggregation.data });
			this.setState({ borrowerHistory: this.props.borrowerCompletedLoansHistory.data });
			this.setState({ makeOffer: { loading: false } });

			// Reset slider & Switch view back to main request card info as well
			this.handleVisibleView("requestData");

			// Post views to the back-end
			const loan_request_Id = this.state.data?.id;
			// console.log('loan_request_data ', this.state.data);
			// console.log('loan_request_data_Id ', loan_request_Id);
			try {
				if (loan_request_Id != null) {

					await PostLoanRequestView(loan_request_Id).then((res) => {
						console.log('postLoanRequestView_SUCCESS ', res?.status)
					})
				}
			} catch (err) {
				console.log('postLoanRequestView_CATCH_ERR ', err)
			}
		}

		const modal = { ...this.state.modal };
		modal[name].visible = true;
		this.setState({ modal });
	}

	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		this.setState({ modal });
	};

	handleVisibleView = (name) => {
		// Reset slider
		this.setState({ offered_interest: 3 });

		// Switch views
		let visible = { ...this.state.visible };

		for (let property in visible) {
			visible[property] = false;
		}

		visible[name] = true;
		this.setState({ visible });
	};

	handleSliderChange = (value) => {
		this.setState({ offered_interest: value });

		// Recalculate expected earning with new offered interest value
		this.setExpectedEarning(this.state.data.loan_amount, this.state.offered_interest, this.state.data.loan_terms);
	};

	numberWithCommas = (number) => {
		if (!number) return null;
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	offerLoanRequest = async (offered_interest) => {
		this.setState({ makeOffer: { loading: true } });
		console.log('offerLoanRequest_offered_interest ', offered_interest);
		await this.props.fetchOfferLoan(offered_interest);

		this.setState({ makeOffer: { loading: false } });
		this.handleVisibleView("requestComplete");
	};

	getLoanRequestInfo = async () => {
		await this.props.fetchLoanRequestInfoData();

	};

	handleMakeOfferCompleteConfirm = () => {
		this.handleCloseModal("requestPopUp");

		if (!this.props.offerLoan.error) {
			this.props.updateOfferLoans();
			return window.location.reload();
		}

		if (this.props.userData.user.account_type === "individual") {
			return this.handleCloseModal("requestPopUp");
		}

		if (this.props.userData.user.registration_step !== 5) {
			return (window.location = "/signup-process");
		}

		if (!this.props.userData.user.has_verified_funding_resource) {
			return (window.location = "/user/bank-transfer");
		}
	};

	setExpectedEarning = (loan_amount, interest_value, payment_terms) => {
		let monthly_gain =
			(loan_amount * (interest_value / 100)) / 12 / (1 - Math.pow(1 + interest_value / 100 / 12, -payment_terms));
		let expected_earning = monthly_gain * payment_terms - loan_amount;
		this.setState({
			expected_earning,
		});
	};

	setCreditScoreIcon = async (score_range) => {
		const score = parseInt(score_range.substring(0, 3));
		var imageName = "";
		if (score >= 300 && score <= 579) {
			imageName = "credit-score-1";
		}
		if (score >= 580 && score <= 669) {
			imageName = "credit-score-2";
		}
		if (score >= 670 && score <= 759) {
			imageName = "credit-score-3";
		}
		if (score >= 760 && score <= 850) {
			imageName = "credit-score-4";
		}
		// Imports corresponding credit score icon file and sets it in the component state
		const creditScoreIcon = images(`./${imageName}.svg`);

		this.setState({
			creditScoreIcon: typeof creditScoreIcon === "string" ? creditScoreIcon : creditScoreIcon.default,
		});
	};

	handleFavoriteLoan = async () => {
		console.log('handleFavoriteLoan')
		this.setState({ favoriteLoading: true });

		// await store.dispatch(fetchFavLoanRequests())
		// await store.dispatch(fetchFavoriteLoan(this.props.data.id))
		await this.props.fetchFavoriteLoan();
		
		this.setState({ favoriteLoading: false });
		this.props.handleRefresh()
	};

	handleUnfavoriteLoan = async () => {
		const { id } = this.state.data;
		console.log('handleUnfavoriteLoan')
		this.setState({ favoriteLoading: true });

		await this.props.fetchUnfavoriteLoan(this.getFavReq(id)?.fav_loan_request_id);
		
		// this.handleTriggerRefreshFavLoans()

		this.setState({ favoriteLoading: false });


	};

	getFavReq = (id) => {
		const { favLoanRequests } = this.props
		if (favLoanRequests?.data?.results?.length > 0) {
			const foundIndex = favLoanRequests?.data?.results?.findIndex((favRequest) => favRequest.id === id);
			if (foundIndex !== -1) return favLoanRequests?.data?.results[foundIndex]
			else return null
		}
		else return null
	}

	render() {
		const { offerLoan, prediction, favRequests, favLoanRequests, loanRequestInfo,fetchLoanRequestInfoData } = this.props;
		const {
			id,
			borrower,
			borrower_card_color,
			total_amount,
			loan_terms,
			fav_loan_request_id,
			lenme_prediction_purchased,
		} = this.state.data;
	
		const {
			modal,
			visible,
			expected_earning,
			offered_interest,
			dataAggregation,
			borrowerHistory,
			creditScoreIcon,
			makeOffer,
			favoriteLoading,
		} = this.state;

		// Check if borrower data has been loaded
		if (!borrower) return null;

		const {
			credit_score,
			lenmo_score,
			avatar,
			initial,
			payment_history,
			credit_utilization,
			deregatory_mark,
			hard_inquiries,
			annual_income,
			total_accounts,
			completed_loans_count,
			loan_request_infoState
		} = borrower;

		// Calculate factor for dataAggregation
		let factor = 98;
		if (dataAggregation && dataAggregation.sum_values) {
			const maxCredit = dataAggregation.sum_values.reduce(function (prev, current) {
				return prev.CREDIT > current.CREDIT ? prev : current;
			}).CREDIT;
			const maxDebit = dataAggregation.sum_values.reduce(function (prev, current) {
				return prev.DEBIT > current.DEBIT ? prev : current;
			}).DEBIT;
			const max = maxCredit > maxDebit ? maxCredit : maxDebit;
			factor = max / 98;
		}
	

		// Render
		return (
			<>
				{/* Request Card */}
				<div className="card request p-3 mt-3">
					<div className="d-flex flex-column">
						<div className="d-flex ">
							<div className="mr-3">
								<LNRequestCardAvatar data={[{ icon: avatar, color: borrower_card_color, initials: initial }]} />
							</div>

							<div className="d-flex flex-wrap">
								<div className="card-item my-2">
									<div className="request-sub-title">Amount</div>
									<div>${handleNumberWithCommas(total_amount)}</div>
								</div>
								<div className="card-item my-2">
									<div className="request-sub-title">Payback period</div>
									<div>
										{loan_terms} {loan_terms > 1 ? "Months" : "Month"}
									</div>
								</div>
								<div className="card-item my-2">
									<div className="request-sub-title">Credit Score</div>
									<div className="d-flex align-items-center">
										<img src={creditScoreIcon} alt={credit_score} />
										<span className="ml-2">{credit_score}</span>
									</div>
								</div>
								<div className="card-item my-2">
									<div className="request-sub-title">Lenme Score</div>
									<div>{lenmo_score}</div>
								</div>
							</div>

							{/* Unfavourited Loan Request */}
							{!favoriteLoading && this.getFavReq(id) === null && (
								<div className="ml-auto pointer" onClick={this.handleFavoriteLoan}>
									<img src={StarGrey} alt="Favorite" />
								</div>
							)}

							{/* Favourited Loan Request */}
							{!favoriteLoading && this.getFavReq(id) !== null && (
								<div className="ml-auto pointer" onClick={this.handleUnfavoriteLoan}>
									<img src={StarYellow} alt="Favorite" />
								</div>
							)}

							{/* Loading Favorite Loan Request */}
							{favoriteLoading && (
								<div className="ml-auto">
									<img src={LikeLoading} alt="Loading..." width="25" />
								</div>
							)}
						</div>

						<div className="d-flex ml-auto">
							<div
								className="btn-link btn-dismiss pointer mr-3 text-decoration-none"
								onClick={() => this.props.fetchDismissLoan()}>
								Dismiss
							</div>
							<div
								className="btn-link btn-offer pointer text-decoration-none"
								onClick={() => this.getLoanRequestInfo().then(()=>{
									this.handleOpenModal("requestPopUp")
								}).catch((err)=>{
									console.log('err',err);
									this.handleOpenModal("requestPopUp")
								})}>
								{makeOffer.loading ? (
									<img className="loading-spinner-offers" src={LoadingOffer} alt="Loading..." />
								) : (
									"Offer"
								)}
							</div>
						</div>
					</div>
				</div>

				{/* Request Popup */}
				<LNModal
					show={modal.requestPopUp.visible}
					onHide={() => this.handleCloseModal("requestPopUp")}
					dialogClassName="request-card-dialog">
					{/* Request Data */}
					{visible.requestData && (
						<div className="d-flex flex-column">
							{/* Avatar Mobile */}
							<div className="d-flex align-items-center d-sm-none pb-4 mb-3 border-bottom">
								<LNAvatar data={[{ icon: avatar, color: borrower_card_color, initials: initial }]} />
								<div className="ml-3">
									<div className="request-sub-title">Credit Score</div>
									<div className="d-flex align-items-center">
										<img src={creditScoreIcon} alt={credit_score} />
										<span className="ml-2 font-weight-bold">{credit_score}</span>
									</div>
								</div>
								<div className="ml-3">
									<div className="request-sub-title">
										Lenme Score
										<InfoIcon
											handleClick={() => this.handleVisibleView("lenmeScore")}
											classes="ml-2"
										/>
									</div>
									<div className="font-weight-bold">{lenmo_score}</div>
								</div>
							</div>

							<div className="d-flex align-items-center d-sm-none">
								<div className="">
									<div className="request-sub-title annual-interest">Amount</div>
									<div className="font-weight-bold">${handleNumberWithCommas(total_amount)}</div>
								</div>
								<div className="ml-4">
									<div className="request-sub-title annual-interest">Payback Period</div>
									<div className="font-weight-bold">
										{loan_terms} {loan_terms > 1 ? "Months" : "Month"}
									</div>
								</div>
							</div>
							{!!loanRequestInfo?.data &&
							<div style={{
								marginLeft:20,
								backgroundColor:
								loanRequestInfo?.data?.highlight?.status == 0  ? 
								"#F4FFFB" 
								: 
								loanRequestInfo?.data?.highlight?.status == 1 ?
								"#F4FBFF"
								:
								"#FFF4EA",
								borderRadius:12,
								width:380,
								height:64,
								marginBottom:30,
								borderColor:"#D9D9D9",
								borderWidth:1,
								borderStyle:"solid",
								padding:10,
								paddingLeft:16,
								}}>
								<div style={{display:"flex", alignItems:"center"}}>
									<img 
										style={{width:30, height:30, marginRight:10}} 
										src={
										loanRequestInfo?.data?.highlight?.status == 0 ? 
										require('../../img/icons/new_Loan.png')
										:
										loanRequestInfo?.data?.highlight?.status == 1 ? 
										require('../../img/icons/In_Demand.png')
										:                  
										require('../../img/icons/hot_Deals.png')
										} 
									/>
									<div>
									<div className="font-weight-bold" style={{
										fontSize:14,
										color:"#000000"
									}}>
										
										{loanRequestInfo?.data?.highlight?.title}									
									</div>
									<div style={{
										fontSize:12,
										fontStyle:"normal",
										color:"#000000"
									}}>
										{loanRequestInfo?.data?.highlight?.message}
									</div>
									</div>
								</div>
							</div>
							}
							<div className="d-flex request-card-wrapper">
								{/* Avatar Tablet */}
								<div className="d-none d-sm-block">
									<LNAvatar offerCard={true}
										data={[{ icon: avatar, color: borrower_card_color, initials: initial }]}
									/>
								</div>
								<div className="request pl-sm-3">
									<div className=" p-3 d-none d-sm-block">
										<div className="d-flex flex-wrap">
											<div className="card-item my-2">
												<div style={{fontSize:14, color:"#000000"}} className="request-sub-title">Amount</div>
												<div style={{fontSize:16, color:"#000000"}} className="request-sub-title" >${handleNumberWithCommas(total_amount)}</div>
											</div>
											<div className="card-item my-2">
												<div style={{fontSize:14, color:"#000000"}} className="request-sub-title">Payback Period</div>
												<div style={{fontSize:16, color:"#000000"}} className="request-sub-title">
													{loan_terms} {loan_terms > 1 ? "Months" : "Month"}
												</div>
											</div>
											<div className="card-item my-2">
												<div style={{fontSize:14, color:"#000000"}} className="request-sub-title">Credit Score</div>
												<div className="d-flex align-items-center">
													<span style={{fontSize:16, color:"#000000", marginRight:5}} className="request-sub-title">{credit_score}</span>
													<img style={{width:6,height:6}} src={creditScoreIcon} alt={credit_score} />
												</div>
											</div>
											<div className="card-item my-2">
												<div style={{fontSize:14, color:"#000000"}}  className="request-sub-title">
													Lenme Score
													<InfoIcon
														handleClick={() => this.handleVisibleView("lenmeScore")}
														classes="ml-2"
													/>
												</div>
												<div style={{fontSize:16, color:"#000000"}} className="request-sub-title">{lenmo_score}</div>
											</div>
										</div>
										<div style={{fontSize:14, color:"#000000", marginTop:8}}className="vantage-score">
											VantageScore 4.0
											<InfoIcon
												handleClick={() => this.handleVisibleView("vantageScore")}
												classes="ml-2"
											/>
										</div>
									</div>
									<div style={{ alignSelf:"center", width:"110%", marginLeft:-70}}>
			
									{/* Info Cards */}
										{this.props.data?.crypto_logo && this.props.data?.crypto_logo !== "" ? (

											<div className="p-2" style={{flex: 1}}>
												<InfoCard 
													offerCard
													label="Backed by crypto"
													crypto_logo={this.props.data?.crypto_logo}
													crypto_name={this.props.data?.crypto_name}
													crypto_amount={this.props.data?.crypto_amount}
													crypto_market_value={this.props.data?.crypto_market_value}
													crypto_currency={this.props.data?.crypto_currency}
													// value={payment_history}
													// string={`${payment_history}%`}
													show_info_function={() => this.handleVisibleView("backedByCrypto")}
												/>
											</div>
										) : null}
										<div className="d-flex flex-wrap mb-3">
										<div className="request-info-card p-2">
											<InfoCard 
												offerCard
												label="Payment History"
												value={payment_history}
												string={`${payment_history}%`}
												show_info_function={() => this.handleVisibleView("paymentHistory")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Credit Utilization"
												value={credit_utilization}
												string={`${Math.ceil(credit_utilization)}%`}
												show_info_function={() => this.handleVisibleView("creditUtilization")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Derogatory Marks"
												value={deregatory_mark}
												string={deregatory_mark.toString()}
												show_info_function={() => this.handleVisibleView("derogatoryMarks")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Hard Inquiries"
												value={hard_inquiries}
												string={hard_inquiries.toString()}
												show_info_function={() => this.handleVisibleView("hardInquiries")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Annual Income"
												value={annual_income}
												string={`$${this.numberWithCommas(annual_income)}/yr`}
												show_info_function={() => this.handleVisibleView("annualIncome")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Total Accounts"
												value={total_accounts}
												string={total_accounts.toString()}
												show_info_function={() => this.handleVisibleView("totalAccounts")}
											/>
										</div>
										<div className="request-info-card p-2">
											<InfoCard
												offerCard
												label="Lenme History"
												value={completed_loans_count}
												string={completed_loans_count.toString() + " Completed Loans"}
												show_info_function={() => this.handleVisibleView("lenmeHistory")}
											/>
										</div>
									</div>
									<div style={{display:"flex", flexDirection:"row"}}>
										<div className="financial-health-title p-2"style={{fontSize:"16px", fontWeight:"initial"}}>Financial Health</div>
										<InfoIcon handleClick={() => this.handleVisibleView("financialHealth")} classes="info-card-info-icon-card" />
									</div>
									{/* Financial Health */}
									<div className="mx-2 mb-4">
										<>
											{/* <div className="financial-health-title p-3">Financial Health Insight</div> */}

											<div className="p-3">
												{this.props.data?.borrower_data_aggs?.avg_credit !== 0 &&
													this.props.data?.borrower_data_aggs?.avg_debit !== 0 &&
													this.props.data?.borrower_data_aggs?.sum_values &&
													this.props.data?.borrower_data_aggs?.sum_values?.length > 0 && (
														<>
															<div
																className="data-aggregation-graph"
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	alignItems: "flex-end",
																	position: "relative",
																}}>
																<div
																	style={{
																		alignItems: "flex-end",
																		display: "inline-block",
																		width: 46,
																	}}>
																	<div
																		style={{
																			display: "flex",
																			alignItems: "flex-end",
																			justifyContent: "center",
																		}}>
																		<div
																			style={{
																				display: "inline-block",
																				width: 11,
																				height:
																				this.props.data?.borrower_data_aggs?.avg_credit / factor,
																				borderRadius: 3.3,
																				backgroundColor: "#05E4B5",
																			}}
																		/>
																		<div
																			style={{
																				display: "inline-block",
																				width: 11,
																				height:
																				this.props.data?.borrower_data_aggs?.avg_debit / factor,
																				marginLeft: 5,
																				borderRadius: 3.3,
																				backgroundColor: "#7659FB",
																			}}
																		/>
																	</div>
																	<p
																		style={{
																			fontWeight: 500,
																			fontFamily: "Poppins",
																			fontSize: 10,
																			color: "#2F2F2F",
																			textAlign: "center",
																		}}>
																		AVERAGE
																	</p>
																</div>

																{this.props.data?.borrower_data_aggs?.sum_values.map((item, index) => (
																	<div
																		key={index}
																		style={{
																			opacity: 0.3,
																			display: "inline-block",
																			width: 46,
																		}}>
																		<div
																			style={{
																				display: "flex",
																				alignItems: "flex-end",
																				justifyContent: "center",
																			}}>
																			<div
																				style={{
																					width: 11,
																					height: item.CREDIT / factor,
																					borderRadius: 3.3,
																					backgroundColor: "#05E4B5",
																					display: "inline-block",
																				}}
																			/>
																			<div
																				style={{
																					width: 11,
																					height: item.DEBIT / factor,
																					marginLeft: 5,
																					borderRadius: 3.3,
																					backgroundColor: "#7659FB",
																					display: "inline-block",
																				}}
																			/>
																		</div>
																		<p
																			style={{
																				fontFamily: "Poppins",
																				fontWeight: 500,
																				fontSize: 10,
																				color: "#2F2F2F",
																				textAlign: "center",
																			}}>
																			{index === 0
																				? "30 Days"
																				: index === 1
																				? "60 Days"
																				: "90 Days"}
																		</p>
																	</div>
																))}

																<div
																	style={{
																		position: "absolute",
																		left: 0,
																		right: 0,
																		bottom:
																			28 + this.props.data?.borrower_data_aggs?.avg_credit / factor,
																		borderWidth: 1,
																		borderStyle: "dashed",
																		borderColor: "#05E4B5",
																		opacity: 0.3,
																	}}
																/>

																<div
																	style={{
																		position: "absolute",
																		left: 0,
																		right: 0,
																		bottom: 28 + this.props.data?.borrower_data_aggs?.avg_debit / factor,
																		borderWidth: 1,
																		borderStyle: "dashed",
																		borderColor: "#7659FB",
																		opacity: 0.3,
																	}}
																/>
															</div>

															<div style={{marginTop:12}} className="d-flex w-100">
																<div style={{
																	backgroundColor:"#F2F3F8",
																	width:162,
																	height:69,
																	borderRadius:10,
																	marginRight:15,
																	padding:12,
																	}} 
																	className="financial-health-fact">
																	<div style={{fontSize:13, color:"#000000"}} className="d-flex justify-content-between financial-health-title">
																		Avg. Expense
																		<div style={{width:13, height:13 , backgroundColor:"#05E4B5", position:"relative", top:2, right:2, borderRadius:2}}/>
																	</div>
																	<div className="d-flex" style={{fontSize:15, color:"#000000", fontWeight:"initial", position:"relative", right:0, marginTop:2}}>${this.props.data?.borrower_data_aggs?.avg_debit}</div>
																</div>
																<div 
																	style={{
																	backgroundColor:"#F2F3F8",
																	width:162,
																	height:69,
																	borderRadius:10,
																	padding:12
																	}}  
																	className="financial-health-fact">
																	<div style={{fontSize:13, color:"#000000"}} className="d-flex justify-content-between financial-health-title">
																		Avg. Income
																		<div style={{width:13, height:13, backgroundColor:"#7659FB", position:"relative", top:2, right:2, borderRadius:2}}/>
																	</div>
																	<div className="d-flex" style={{fontSize:15, color:"#000000", fontWeight:"initial", position:"relative", right:0, marginTop:2}}>${this.props.data?.borrower_data_aggs?.avg_credit}</div>
																</div>
															</div>
														</>
													)}

												{this.props.data?.borrower_data_aggs?.avg_credit === 0 &&
													this.props.data?.borrower_data_aggs?.avg_debit === 0 && (
														<div className="font-weight-bold h5 mb-0">
															Sorry, we couldn't analyze {initial}'s financial health this
															time.
														</div>
													)}
											</div>
										</>

										{/* <div
											className="financial-health-info flex-grow-1 justify-content-start pointer p-3"
											onClick={() => this.handleVisibleView("financialHealth")}>
											What is financial health insight?
										</div> */}
									</div>
								
									{/* Lenme Predict */}
									<div className="card mx-2 lenme-predict-locked d-none">
										<div className="d-flex align-items-center p-3">
											<div className="flex-grow-1 font-weight-normal">Lenme Predict</div>
											<div>
												<InfoIcon handleClick={() => this.handleVisibleView("lenmePredict")} />
											</div>
										</div>

										<div className="p-3">
											LenmePredict is a machine learning-powered algorithm that uses borrower
											transaction history to predict the likelihood of loan payoff.
										</div>

										<div className="d-flex align-items-center p-3">
											<img src={LockIcon} alt="Lock" />
											<div className="ml-3">
												You have used all of your LenmePredict balance. Make one more accurate
												decision with a One-time purchase!
											</div>
										</div>

										<div className="d-flex align-items-center">
											<div className="flex-grow-1 font-weight-normal px-3">
												confidence level {"90%"}
												<InfoIcon
													handleClick={() => this.handleVisibleView("confidenceLevel")}
													classes="ml-2"
												/>
											</div>
											<div>
												<button
													onClick={() => this.handleVisibleView("subscribeNowAndSave")}
													className="btn btn-black rounded-0 px-4 py-2">
													$0.99
												</button>
											</div>
										</div>
									</div>	
									
									<div style={{marginLeft:10}}>
										<div style={{width:"99%",}} className="d-sm-flex align-items-center pt-4 justify-content-between mb-2">
											<div style={{fontSize:14, color:"#000000",}} className="font-weight-bold">Annual Interest Rate</div>
											<div className="expected-earning pl-sm-1">
												Expected earning ${parseFloat(expected_earning).toFixed(2)}
											</div>
										</div>
										<div className="interest-rate">{offered_interest}%</div>

										<div style={{width:"96%",alignSelf:"center"}}>
											<LNRangeSlider
												min={3}
												max={
													this.props.data?.max_interest_rate != null
													? this.props.data?.max_interest_rate * 100
													: 200
												}
												value={3}
												change_func={(new_val) => this.handleSliderChange(new_val)}
											/>
										</div>

										<hr className="w-100" />
									</div>
									{/* Buttons */}
									<div className="modal-buttons-wrapper horizontal">
										<button
											className="btn shadow btn-lg text-danger"
											onClick={() => this.props.fetchDismissLoan()}>
											Dismiss
										</button>

										<button
											className="btn btn-black btn-lg"
											onClick={() => this.offerLoanRequest(offered_interest)}>
											Make offer
										</button>
									</div>
								</div>
								</div>
							</div>
						</div>
					)}
					{/* Beginning of Info views */}

					{/* Lenme Score Info */}
					{visible.lenmeScore && (
						<InfoCardDetails
							title="Lenme Score"
							legend={LenmeScoreLegend}
							body="A borrower's Lenme Score is an indication of their risk profile. Lenme Scores are a combination of the borrower's credit report information, affordability of the borrower to pay the loan back (based on their annual income and the monthly installment), and the history of their behavior using Lenme. The Lenme score is a rating out of 100. The higher the score, the lower the risk."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Vantage Score Info */}
					{visible.vantageScore && (
						<InfoCardDetails
							title="Vantage Score"
							legend={VantageScoreLegend}
							body="VantageScore 4.0 is the latest credit scoring model. This model generates consumer
							credit scores that are more predictive, more consisten, and mre stable. Credit
							scores range from 300-850."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* backed By Crypto Info */}
					{visible.backedByCrypto && (
						<InfoCardDetails
							title="Crypto-Backed Loans"
							// legend={PaymentHistoryLegend}
							body="Crypto-backed loans are loans backed by cryptocurrencies. Lenme holds 
							enough crypto to coer the loan amount at any given time in case of defualt. 
							In case of defualt, Lenme liquidates the cryptocurrency and pays the investor the remaining balance. 
							Lenme may also liquidatye the currency if the value falls below a defined threshold."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Payment History Info */}
					{visible.paymentHistory && (
						<InfoCardDetails
							title="Payment History"
							legend={PaymentHistoryLegend}
							body="Payment history is the borrower's history of payments to credit grantors. The lower
							the percentage, the higher the risk due to missed or late payments. The higher the
							percentage, the lower the risk."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Credit Utilization Info */}
					{visible.creditUtilization && (
						<InfoCardDetails
							title="Credit Utilization"
							legend={CreditUtilizationLegend}
							body="Credit utilization is the ratio of your credit card balances to credit limits. It
							measures the amount of the total available credit that the borrower is currently
							using."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Derogatory Marks Info */}
					{visible.derogatoryMarks && (
						<InfoCardDetails
							title="Derogatory Marks"
							legend={DerogatoryMarksLegend}
							body="A derogatory item indicates a serious delinquency or late payment. This includes
														items sent to collections as well as public record items such as bankruptcies and
														tax liens. The higher the number of derogatory items, the higher the risk."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Hard Inquiries Info */}
					{visible.hardInquiries && (
						<InfoCardDetails
							title="Hard Inquiries"
							legend={HardInquiriesLegend}
							body="Hard inquiries are credit inquiries where a potential lender is reviewing borrower's
										credit because they've applied for credit with them. These include credit checks
										when you've applied for an auto loan, mortgage or credit card. Hard inquiries from
										things like credit applications can stay on your report for up to 2 years, but their
										effects tend to fade over time."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Annual Income Info */}
					{visible.annualIncome && (
						<InfoCardDetails
							title="Annual Income"
							body="Annual income is the estimated annual salary of the borrower reported to the IRS."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Total Account Info */}
					{visible.totalAccounts && (
						<InfoCardDetails
							title="Total Accounts"
							legend={TotalAccountsLegend}
							body="The more accounts a borrower has, the less of an investment risk they are."
							visible_function={() => {
								this.handleVisibleView("requestData");
							}}
						/>
					)}

					{/* Lenme History Info */}
					{visible.lenmeHistory && (
						<RequestsHistory
							user_id={53}
							visible_function={(view) => {
								this.handleVisibleView(view);
							}}
							borrower_history={borrowerHistory}
							borrower_credit_score={credit_score}
							borrower_lenmo_score={lenmo_score}
							borrower_avatar_icon={avatar}
							borrower_avatar_color={borrower_card_color}
							borrower_avatar_initials={initial}
						/>
					)}

					{/* Financial Health Info */}
					{visible.financialHealth && (
						<div>
							<PopupHeader
								title="What is financial health insight?"
								handleBackClick={() => this.handleVisibleView("requestData")}
							/>

							<div className="modal-left-space-container">
								<div className="mt-4">
									<img src={FinancialHealthLegend} alt="Financial Health Legend" />
								</div>

								<div className="small mt-4">
									Financial health insight gives you a breakdown of the last 90 days of the borrower's
									financial activity. You will get a view into income vs. expenses for the last 30,
									60, and 90 days. Please note that the information is not self-reported and based on
									data from their primary bank account.
								</div>
							</div>

							<div className="modal-buttons-wrapper horizontal">
								<button
									onClick={() => this.handleVisibleView("requestData")}
									className="btn btn-black btn-lg">
									Got it
								</button>
							</div>
						</div>
					)}

					{/* Success/Failure Message */}
					{visible.requestComplete && (
						<div>
							{offerLoan.error && <div className="font-weight-bold">Currently unable to invest!</div>}
							{!offerLoan.error && <div className="font-weight-bold">Success!</div>}

							{offerLoan.error && <div className="font-size-14 mt-3">{offerLoan.error}</div>}
							{!offerLoan.error && (
								<div className="font-size-14 mt-3">
									Your offer has been sent to {initial}.{<br />}
									We will let you know in the next 24 hours if your offer was accepted.
								</div>
							)}

							<div className="text-center my-4">
								{offerLoan.error && <ResponseIllustration type="error" />}
								{!offerLoan.error && <ResponseIllustration type="success" />}
							</div>

							<div className="modal-buttons-wrapper horizontal">
								<button onClick={this.handleMakeOfferCompleteConfirm} className="btn btn-black btn-lg">
									Done
								</button>
							</div>
						</div>
					)}

					{/* What is Lenme Predict */}
					{visible.lenmePredict && (
						<div>
							<PopupHeader
								title="What is Lenme Predict?"
								handleBackClick={() => this.handleVisibleView("requestData")}
							/>

							<div className="modal-left-space-container">
								<div className="mt-5 font-size-14">
									LenmePredict is a machine learning-powered algorithm that predicts the likelihood of
									the borrower to pay back the loan. LenmePredict uses historical data to predict
									future events based on 2,500 data elements.
									<br />
									<br />
									Machine learning algorithms build a mathematical model based on historical data,
									known as "training data", in order to make predictions without being explicitly
									programmed to perform the task.
								</div>

								<div className="text-center mt-5">
									<img src={LenmePredict} alt="Lenme Predict" />
								</div>

								<div className="mt-5 small text-muted">
									Disclaimer: Lenme never makes decisions or suggestions on whether a consumer should
									receive a loan. LenmePredict should be used as an informative indicator and not for
									credit or underwriting guidance. Lenme predict is built on credit bureau data,
									banking data, and behavioral data. Information like age, sex, race, location or
									marital status is not included in this algorithm.
								</div>

								<div className="modal-buttons-wrapper vertical">
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn btn-black btn-block btn-lg">
										Got it
									</button>
									<button className="btn btn-black btn-block btn-lg">$0.99</button>
								</div>
							</div>
						</div>
					)}

					{/* Subscribe Now & Save */}
					{visible.subscribeNowAndSave && (
						<div>
							<PopupHeader
								title="Subscribe Now &amp; Save"
								handleBackClick={() => this.handleVisibleView("requestData")}
							/>

							<div className="modal-left-space-container">
								<div className="mt-3 text-purple">Make more accurate decisions</div>

								<div className="font-size-14 mt-3">
									LenmePredict provides a prediction of how likely the borrower is to return their
									loans and allows you to make more accurate decisions.
								</div>

								<div className="mt-5">
									<LNRadioInput
										label="One-Time Purchase"
										subLabel="$0.99/prediction"
										name="lenmePredict"
										value="1"
										defaultChecked={true}
									/>
									<LNRadioInput
										label="Monthly Subscription - Coming soon"
										name="lenmePredict"
										value="2"
										disabled={true}
									/>
									<LNRadioInput
										label="Annual Subscription - Coming soon"
										name="lenmePredict"
										value="3"
										disabled={true}
									/>
								</div>

								<div className="mt-5">
									<button
										onClick={() => this.handleVisibleView("paymentOptions")}
										className="font-size-14 btn btn-link p-0">
										Other payment option?
									</button>
								</div>

								<div className="modal-buttons-wrapper vertical mt-0">
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn btn-black btn-block btn-lg">
										<img src={AppleIcon} alt="Apple Pay" />
									</button>
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn shadow btn-block btn-lg">
										<img src={GoogleIcon} alt="Google Pay" />
									</button>
								</div>
							</div>
						</div>
					)}

					{/* What is Confidence level */}
					{visible.confidenceLevel && (
						<div>
							<PopupHeader
								title="What is Confidence level?"
								handleBackClick={() => this.handleVisibleView("requestData")}
							/>

							<div className="modal-left-space-container">
								<div className="mt-5 font-size-14">
									A confidence level is a percentage between 0 and 100% that indicates the probability
									that a given prediction is correct. For example, if the machine suggests a borrower
									is unlikely to pay back the loan and the confidence level is 90%. It means the
									system has 90% confidence that this borrower is unlikely to pay.
								</div>

								<div className="text-center mt-5">
									<img src={ConfidenceLevel} alt="Confidence Level" />
								</div>

								<div className="modal-buttons-wrapper vertical">
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn btn-black btn-block btn-lg">
										Got it
									</button>
								</div>
							</div>
						</div>
					)}

					{/* Other payment options */}
					{visible.paymentOptions && (
						<div>
							<PopupHeader
								title="Other payment options?"
								handleBackClick={() => this.handleVisibleView("requestData")}
							/>

							<div className="modal-left-space-container">
								<div className="mt-5 font-size-14">
									Hang tight, other payment options will soon be available! Thank you for your
									patience!
								</div>

								<div className="text-center mt-5">
									<img src={PaymentObtions} alt="Payment Obtions" />
								</div>

								<div className="modal-buttons-wrapper vertical">
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn btn-black btn-block btn-lg">
										<img src={AppleIcon} alt="Apple Pay" />
									</button>
									<button
										onClick={() => this.handleVisibleView("requestData")}
										className="btn shadow btn-block btn-lg">
										<img src={GoogleIcon} alt="Google Pay" />
									</button>
								</div>
							</div>
						</div>
					)}
				</LNModal>
			</>
		);
	}
}

// Prop Types
RequestCard.propTypes = {
	data: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
		yodleeDataAggregation: state.dashboard.yodleeDataAggregation,
		borrowerCompletedLoansHistory: state.dashboard.borrowerCompletedLoansHistory,
		favoriteLoan: state.dashboard.favoriteLoan,
		unfavoriteLoan: state.dashboard.unfavoriteLoan,
		dismissLoan: state.dashboard.dismissLoan,
		offerLoan: state.dashboard.offerLoan,
		favLoanRequests: state.dashboard.favLoanRequests
	};
}

function mapDispatchToProps(dispatch, props) {
	const borrowerId = props.data.borrower.id;
	const requestId = props.data.id;

	return {
		fetchYodleeDataAggregation: () => dispatch(fetchYodleeDataAggregation(borrowerId)),
		fetchBorrowerCompletedLoansHistory: () => dispatch(fetchBorrowerCompletedLoansHistory(borrowerId)),
		fetchFavoriteLoan: () => dispatch(fetchFavoriteLoan(requestId)),
		fetchUnfavoriteLoan: (favRequestId) => dispatch(fetchUnfavoriteLoan(requestId, favRequestId)),
		fetchDismissLoan: () => dispatch(fetchDismissLoan(requestId)),
		fetchOfferLoan: (offeredInterest) => dispatch(fetchOfferLoan(requestId, offeredInterest)),
		updateOfferLoans: () => dispatch(updateOfferLoans(requestId)),
	};
}

// Wrap your component with forwardRef before connecting it
const ForwardRefRequestCard = forwardRef((props, ref) => (
	<RequestCard {...props} innerRef={ref} />
));
export default connect(mapStateToProps, mapDispatchToProps,null, { forwardRef: true })(RequestCard);
