import axios from "axios";
import moment from "moment";

// Keys
const refreshTokenKey = "refreshToken";
const accessTokenKey = "accessToken";
const currentAccessTokenDate = "currentAccessTokenDate";
// let lastRequestDate = null;

async function getToken() {
	console.log('getToken_refreshTokenInterceptor')
	const lastRequestDate = localStorage.getItem(currentAccessTokenDate);
	if (!lastRequestDate) {
		logoutUser();
		// throw new Error("lastRequestDate_Not_Found");
		return Promise.reject("lastRequestDate_Not_Found");
	}

	if (!isTokenExpired(lastRequestDate)) {
		return localStorage.getItem(accessTokenKey);
	}
	// Getting refresh token
	console.log('isTokenExpired ', isTokenExpired(lastRequestDate))
	try {
		const res = await refreshExpiredToken();
		return res;
	} catch (err) {
		console.log('refreshExpiredToken_err ', err);
		logoutUser();
		// throw err; // Re-throw the error to be handled elsewhere
		const _error = err ?? 'refreshExpiredToken_catch_Err';
		return Promise.reject(_error);
	}
	// const res = refreshExpiredToken().catch((err) => console.log('refreshExpiredToken_err ', err));
	// if (res) return res
	// else return null;
}

function isTokenExpired(lastRequestDate) {
	const lastDateMoment = moment(lastRequestDate);
	let end = moment().utc();
	// let minutes = moment.duration(end.diff(lastRequestDate)).minutes();
	const minutes = end.diff(lastDateMoment, 'minutes');
	// if (minutes < 5) return false;
	console.log('token_diff_minutes ', minutes);
	// 300 mins = 5 hours
	if (minutes > 300) return true;
	else return false
	// return true;
}

async function refreshExpiredToken() {
	try {
		const expiredToken = localStorage.getItem(refreshTokenKey);
		if (!expiredToken) {
			logoutUser();
			// return null;
			return Promise.reject('No_refreshTokenKey');
		}
		const axiosInstance2 = axios.create()
		const response = await axiosInstance2.post("/token/refresh/", { refresh: expiredToken });
		console.log('response_status ', response.status)
		if (response.status === 200 || response.status === 201) {
			console.log('refreshExpiredToken_else')
			localStorage.setItem(accessTokenKey, response.data.access);
			const currentDate = moment().utc().format();
			localStorage.setItem(currentAccessTokenDate, currentDate);
			return response.data.access;
			
		} else {
			logoutUser();
			// return null;
			return Promise.reject('refreshExpiredToken response status else');
		}
		// console.log('refreshExpiredToken_try')
		// return null 
	} catch (ex) {
		console.log("Error: ", ex);
		// alert("Your session has been expired.");
		logoutUser();
		// return null
		return Promise.reject(`refreshExpiredToken response status catch ${ex}`);
	}
}

function logoutUser() {
	localStorage.removeItem(refreshTokenKey);
	localStorage.removeItem(accessTokenKey);
	localStorage.removeItem(currentAccessTokenDate);
	window.location = "/login";
}

export default getToken;
