import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCompanyData, updateUserCompany } from "../../redux";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services

// Import Utils
import { getRegionList } from "../../utils/handleRegions";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNInputMask from "../common/LNInputMask/LNInputMask";
import LNSelect from "../common/LNSelect/LNSelect";

// Import Images
import ArrowRight from "../../img/icons/black/arrow-right.svg";

// Import CSS
import "./editCompanyInfo.css";
import moment from "moment";
import LNDatePicker from "../common/LNDatePicker/LNDatePicker";
import LNRadioInput from "../common/LNRadioInput/LNRadioInput";
import LNUpload from "../common/LNUpload/LNUpload";
import LNError from "../common/LNError/LNError";

class EditCompanyInfo extends Component {
	constructor(props) {
		super(props);
		this.formikRef = React.createRef(null);
		this.state = {
			error: false,
			errorMessage: null,
			finalType: this.props?.companyData?.company?.type,
			unmaskedSSN: this.props?.companyData?.company?.ssn,
			firstTimeSSN: true
		};

		// Validations
		this.soleValidationSchema = Yup.object().shape({
			type: Yup.string()
				.required("Required field")
				.max(255, "Too Long!"),
			dba: Yup.string().nullable().max(255, "Too Long!"),
			ssn: !this.state.firstTimeSSN && Yup.string().required("ssn is required field.").min(9, "SSN Should be 9 digits.").max(9, "SSN Should be 9 digits."),
			dob: Yup.string()
				.required("Date of birth is required for sole-Proprietorship company.")
				.test("dob", "You need to be atleast 18 years old.", (value) => {
					if (value === undefined) {
						return true;
					} else {
						return moment().diff(moment(new Date(value)), "years") >= 18;
					}
				}),
				
			street_address_1: Yup.string()
				.required("Required field")
				.max(255, "Too Long!"),
				
			street_address_2: Yup.string().nullable().max(255, "Too Long!"),
			city: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			// phone: Yup.string()
			// 	.trim()
			// 	.matches(/^\+1[^1][0-9]{9}$/, "Please enter a valid U.S. phone number.")
			// 	.required("Required field"),
			website: Yup.string()
				.nullable()
				.url("Please enter a valid URL.")
				,
			sole_file:
				Yup.string()
				// .max(10, "Too Long!")
				.required("Required field"),
			// legal_document_file: Yup.string().nullable(),	
		});

		this.othersValidationSchema = Yup.object().shape({
			type: Yup.string()
				.required("Required field")
				.max(255, "Too Long!"),
			dba: Yup.string().nullable().max(255, "Too Long!"),
				
			street_address_1: Yup.string()
				.required("Required field")
				.max(255, "Too Long!"),
				
			street_address_2: Yup.string().nullable().max(255, "Too Long!"),
			city: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			// phone: Yup.string()
			// 	.trim()
			// 	.matches(/^\+1[^1][0-9]{9}$/, "Please enter a valid U.S. phone number.")
			// 	.required("Required field"),
			website: Yup.string()
				.nullable()
				.url("Please enter a valid URL.")
				,
			legal_document_number: Yup.string().required("Required field")
			// legal_document_file: Yup.string().test({
			// 	name: 'legal_document_file',
			// 	test: function (value) {
			// 		const legalDocumentNumber = this.parent.legal_document_number;
			// 		return !legalDocumentNumber || (legalDocumentNumber && legalDocumentNumber.length === 0) || !!value;
			// 	},
			// 	message: 'Legal Document File is required'
			// })
			
		});
	}

	handleFormikRef = () => {
		// Update some state
		// this.setState({ someState: newValue });
	
		// Access Formik context and set field value
		if (this.formikRef && this.formikRef.current) {
			this.formikRef.current.setFieldValue('legal_document_number', "");
			this.formikRef.current.setFieldValue('legal_document_file', "");
		}
	};

	// When Mount
	componentDidMount() {}

	// Helper Methods
	handleMethod = () => {};

	maskSSN = (ssn) => {
		if (ssn) {

			return ssn.replace(/\d{5}(\d{4})/, 'XXX-XX-$1');
		}
	}

	render() {
		const { error, errorMessage } = this.state;

		const { companyData, updateUserCompany, reset_view_func } = this.props;
		const { company } = companyData;
		// this.setState({ unmaskedSSN: company?.ssn })
		const originalSSN = company?.ssn;

		// const originalSSNTest = "123123322";
		const maskedSSN = this.maskSSN(originalSSN);
		console.log('maskedSSN ', maskedSSN);


		const companyInitData = {...company, ssn: maskedSSN}
		
		console.log('company__ ', company)
		console.log('companyInitData ', companyInitData)

		if (company?.type !== 'sole' || this.state.finalType !== 'sole') {
			console.log('handleFormikRef')
			this.handleFormikRef()
		}

		// Check if loading
		if (companyData.loading) return <div>Loading...</div>;

		// Render
		return (
			<div>
				<div className="d-flex flex-row align-items-center">
					<div
						className="profile-page-link"
						onClick={() => {
							reset_view_func();
						}}>
						Profile Page
					</div>
					<img src={ArrowRight} alt="Arrow Right" className="profile-page-arrow" />
					<div className="profile-page-link-name">Company Information</div>
				</div>
				<div className="row mt-5">
					<div className="col-6 offset-2">
						<div className="profile-section-title">Company Information</div>

						{/* Form */}
						<Formik
							initialValues={companyInitData}
							innerRef={(formik) => (this.formikRef.current = formik)}
							validationSchema={() => ( this.state.finalType && this.state.finalType === 'sole' ? this.soleValidationSchema : this.othersValidationSchema )}
							onSubmit={async (values, { setSubmitting, setErrors }) => {
								this.setState({ finalType: values?.type })
								console.log('handle_onSubmit')
								console.log('handle_onSubmit_values ', values)
								setSubmitting(true);
								const postData = {
									type: values?.type,
									dba: values?.dba,
									// ssn: this.state.unmaskedSSN?.replace(/-/g, "").replace(/X/g, ""),
									ssn: this.state.unmaskedSSN?.replace(/-/g, "")?.replace(/X/g, ""),
									dob: values?.dob,
									sole_file: values?.sole_file,
									street_address_1: values?.street_address_1,
									street_address_2: values?.street_address_2,
									city: values?.city,
									state: values?.state,
									zip_code: values?.zip_code,
									phone:
										values?.phone?.charAt(0) === "+"
											? values?.phone
											: "+1" + values?.phone?.replace(/-/g, "")?.replace(/X/g, ""),
									website: values?.website,
									legal_document_number: values?.legal_document_number,
									legal_document_file: values?.legal_document_file,
								};
								console.log('postData_before ', postData)
								Object.keys(postData).forEach(key => {
									if (!postData[key]) delete postData[key];
								});
								console.log('postData_after ', postData)
								// Send Update request
								try {
									await updateUserCompany(postData).catch((err) => {
										const error = err ?? 'updateUserCompany_catch_Err';
										return Promise.reject(error);
									})
									this.setState({ error: false });
									setSubmitting(false);
									// Send back to main profile page
									reset_view_func();
								} catch (error) {
									console.log('error ', error)
									let err_res = error?.response?.data;
									let err_to_display = null;
									if (err_res?.legal_document_number[0]) {
										console.log('err_res?.legal_document_number[0] ', err_res?.legal_document_number[0])
										err_res = err_res?.legal_document_number[0]
										err_to_display = 'field is required.'
									}
									this.setState({
										error: true,
										errorMessage: err_to_display ? err_to_display : err_res,
									});
									setErrors(err_res);
									setSubmitting(false);
									
								}
								
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								setSubmitting,
								resetForm,
							}) => (
								<form onSubmit={handleSubmit} spellCheck={false}>
									<div className="inputs-wrapper">
										{error && <LNError text={errorMessage} />}
										{/* {errors && <LNError text={errors} />} */}

										<LNSelect
											size="lg"
											name="type"
											value={values?.type}
											defaultOption={{ value: "", name: "Company type", disabled: false }}
											options={[
												{
													name: "Sole Proprietorship",
													value: "sole",
												},
												{ name: "C-Corporation", value: "c-cop" },
												{ name: "S-Corporation", value: "s-cop" },
												{ name: "LLC", value: "llc" },
												{ name: "Partnership", value: "part" },
											]}
											onChange={e => {
												resetForm();
												setFieldValue("type", e.target.value);
												this.setState({ finalType: e.target.value })
											}}
											onBlur={handleBlur}
											error={touched?.type && errors?.type ? `* ${errors?.type}` : ""}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											optional={true}
											name="dba"
											value={values?.dba}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Doing business as"
											error={touched.dba && errors?.dba ? `* ${errors?.dba}` : ""}
										/>

										{/* Sole */}
										{values?.type?.trim() !== "" && values?.type === "sole" && (

											<div>
												<LNInputMask
													mask="999-99-9999"
													maskChar={null}
													// defaultValue={values?.ssn?.slice(-4)}
													value={values?.ssn}
													onChange={e => {
														this.setState({ firstTimeSSN: false })
														console.log('e.target.value ', e.target.value)

														const value =
															e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
														setFieldValue("ssn", value);
														
														this.setState({ unmaskedSSN: value})
													}}
													maxCharacters={11}
													onBlur={handleBlur}
													onKeyDown={(e) => {
														// Check if the pressed key is the Backspace key
														if (e.key === 'Backspace' || e.key === 'Delete') {
															// Prevent the default behavior of the Backspace key
      														e.preventDefault();
															// Get the cursor position
															if (values?.ssn !== '') {

																// Clear the input value
																setFieldValue("ssn", "");
															}
														}
													}}
													// onClick={() => {
													// 	setFieldValue("ssn", "");
													// }}
													size="lg"
													type="text"
													optional={false}
													name="ssn"
													placeholder="Social security number"
													text="Example: 123-45-6789"
													firstTimeSSN={this.state.firstTimeSSN ? true : null}
													error={touched.ssn && errors?.ssn ? `* ${errors?.ssn}` : ""}
												/>

												<LNDatePicker
													size="lg"
													optional={false}
													name="dob"
													value={values?.dob}
													placeholder="Date of birth"
													dateFormat="MM/dd/yyyy"
													onChange={(date) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))}
													onBlur={handleBlur}
													error={touched.dob && errors?.dob ? `* ${errors?.dob}` : ""}
												/>

												<div className="mb-3 mt-1">
													{errors?.sole_file ? (

														<div className="" style={{fontSize: '12px', lineHeight: '18px', color: '#FF5050'}}>* {errors?.sole_file}</div>
													) : null}
													<span className="text-muted d-block mb-3">Upload Identification</span>
													<div className="mb-2">
														<div className="mb-2">
															<LNRadioInput
																id="idCard"
																name="file_type"
																label="ID Card"
																value="idCard"
																onChange={handleChange}
															/>
														</div>

														{values.file_type === "idCard" && (
															<LNUpload
																document_type="idCard"
																change_function={(file_id) => {
																	setFieldValue("sole_file", file_id);
																}}
																set_loading={(state) => {
																	setSubmitting(state);
																}}
															/>
														)}
													</div>

													<div className="mb-2">
														<div className="mb-2">
															<LNRadioInput
																id="license"
																name="file_type"
																label="Driver's License"
																value="license"
																onChange={handleChange}
															/>
														</div>

														{values?.file_type === "license" && (
															<LNUpload
																document_type="license"
																change_function={(file_id) => {
																	setFieldValue("sole_file", file_id);
																}}
																set_loading={(state) => {
																	setSubmitting(state);
																}}
															/>
														)}
													</div>
												</div>	
											</div>
										)}

										{/* Not Sole */}
										{values?.type?.trim() !== "" && values?.type !== "sole" && (
											<div className="mt-3">
											{errors?.legal_document_number || errorMessage === 'field is required.' ? (

												<div className="" style={{fontSize: '12px', lineHeight: '18px', color: '#FF5050'}}>* field is required.</div>
											) : null}
											<span className="text-muted d-block mb-3">Upload Document</span>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														id="ein"
														name="file_type"
														label="EIN"
														value="ein"
														onChange={(e) => {
															setFieldValue("legal_document_number", "");
															setFieldValue("legal_document_file", "");
															setFieldValue("file_type", e.target.value);
														}}
													/>
												</div>

												{values?.file_type === "ein" && (
													<>
														<LNUpload
															document_type="ein"
															change_function={(file_id) => {
																setFieldValue("legal_document_file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
														/>

														<LNInputMask
															mask="99 - 9999999"
															maskChar="X"
															value={values?.legal_document_number}
															onChange={(e) => {
																const value =
																	e.target.value
																		// .replace(/-/g, "")
																		.replace(/ /g, "")
																		.replace(/X/g, "") || "";
																setFieldValue("legal_document_number", value);
															}}
															onBlur={handleBlur}
															size="lg"
															type="text"
															optional={false}
															name="legal_document_number"
															placeholder="EIN"
															error={
																touched?.legal_document_number &&
																errors?.legal_document_number
																	? `* ${errors.legal_document_number}`
																	: ""
															}
														/>
													</>
												)}
											</div>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														id="tax"
														name="file_type"
														label="Tax ID"
														value="tax"
														onChange={(e) => {
															setFieldValue("legal_document_number", "");
															setFieldValue("legal_document_file", "");
															setFieldValue("file_type", e.target.value);
														}}
													/>
												</div>

												{values?.file_type === "tax" && (
													<>
														<LNUpload
															document_type="tax"
															change_function={(file_id) => {
																setFieldValue("legal_document_file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
														/>

														<LNInputMask
															mask="99 - 9999999"
															maskChar="X"
															value={values?.legal_document_number}
															onChange={(e) => {
																const value =
																	e.target.value
																		//.replace(/-/g, "")
																		.replace(/ /g, "")
																		.replace(/X/g, "") || "";
																setFieldValue("legal_document_number", value);
															}}
															onBlur={handleBlur}
															size="lg"
															type="text"
															optional={false}
															name="legal_document_number"
															placeholder="Tax ID"
															error={
																touched?.legal_document_number &&
																errors?.legal_document_number
																	? `* ${errors.legal_document_number}`
																	: ""
															}
														/>
													</>
												)}
											</div>
										</div>
										)}
	
									</div>

									<LNFloatingInput
										size="lg"
										type="text"
										name="street_address_1"
										value={values?.street_address_1}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Company Address 1"
										error={
											touched?.street_address_1 && errors?.street_address_1
												? `* ${errors.street_address_1}`
												: ""
										}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										optional={true}
										name="street_address_2"
										value={values?.street_address_2}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Company Address 2"
										error={
											touched?.street_address_2 && errors?.street_address_2
												? `* ${errors.street_address_2}`
												: ""
										}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										name="city"
										value={values?.city}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="City"
										error={touched?.city && errors?.city ? `* ${errors.city}` : ""}
									/>

									<LNSelect
										name="state"
										size="lg"
										value={values.state}
										onChange={handleChange}
										onBlur={handleBlur}
										defaultOption={{ name: "State", value: "" }}
										options={getRegionList("US")}
									/>

									<LNInputMask
										mask="99999"
										maskChar="X"
										value={values.zip_code}
										onChange={e => {
											const value = e.target.value.replace(/-/g, "").replace(/X/g, "");
											setFieldValue("zip_code", value);
										}}
										onBlur={handleBlur}
										size="lg"
										type="text"
										name="zip_code"
										placeholder="Zip Code"
										error={touched?.zip_code && errors?.zip_code ? `* ${errors.zip_code}` : ""}
									/>

									<LNInputMask
										mask="999-999-9999"
										maskChar="X"
										value={values.phone}
										onChange={e => {
											const value =
												"+1" + e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
											setFieldValue("phone", value);
										}}
										onBlur={handleBlur}
										size="lg"
										type="text"
										name="phone"
										placeholder="Company Phone Number"
										error={touched?.phone && errors?.phone ? `* ${errors.phone}` : ""}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										name="website"
										value={values?.website}
										optional={true}
										onChange={e => {
											const value =
												"https://" +
												e.target.value.replace("https://", "").replace("http://", "");
											setFieldValue("website", value);
										}}
										onBlur={handleBlur}
										placeholder="Company Website"
										error={touched?.website && errors?.website ? `* ${errors.website}` : ""}
									/>

									<div
										className="btn btn-lg btn-black w-100 mt-5"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Save
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		companyData: state.user.companyData,
	};
}
function mapDispatchToProps(dispatch) {
	return {
		fetchCompanyData: () => dispatch(fetchCompanyData()),
		updateUserCompany: updated_data => dispatch(updateUserCompany(updated_data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyInfo);
