import sentryDSN from "../config/config.json";
import sentryRelease from "../config/config.json";
import * as Sentry from "@sentry/react";

// Services
import { getCurrentAccount } from "./authService";
import { getUser } from "./userService";

async function init() {
	Sentry.init({
		dsn: "https://4fe682637dfb2cf8ad8e9463582469f0@o131099.ingest.sentry.io/4506655304515584", 
		integrations: [
			Sentry.replayIntegration({
			  // Additional SDK configuration goes in here, for example:
			  maskAllText: false,
			  blockAllMedia: false,
			}),
		  ],
		release: sentryRelease,
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		// dsn: sentryDSN,
		// release: sentryRelease,
		// environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	});

	// Check if user is logged in
	if (getCurrentAccount()) {
		// Assign user email to Sentry issue
		setTimeout(async () => {
			const { data } = await getUser();
			Sentry.setUser({ email: data.email });
		}, 3000);
	}
}

function log(error) {
	Sentry.captureException(error);
	// console.log(error);
}

export { init, log };
