import { CountryRegionData } from "react-country-region-selector";

// Takes ISO-2 as parameter and returns list of respective regions as a name/value array
export function getRegionList(countryCode) {
	// Validation
	if (!countryCode || countryCode.trim() === "") {
		alert("Country code is required.");
	}

	if (countryCode?.length !== 2) {
		alert("Country code length must be exactly 2 characters.");
	}

	// Get list of countries and regions
	const parsed = CountryRegionData.map(([country_name, country_code, regions]) => ({
		country_name,
		country_code,
		regions: regions
			.split("|")
			.map(regionData => regionData.split("~"))
			.map(([region_name, region_code]) => ({ region_name, region_code })),
	}));

	// Get list of regions belonging to country code passed in params
	const regions = parsed
		.find(country => country.country_code === countryCode)
		.regions.map(({ region_name, region_code }) => ({
			name: region_name,
			value: region_code,
		}));

	// Removes US non-state territories
	let filteredregions;

	if (countryCode === "US") {
		const blacklist = ["AS", "FM", "GU", "MH", "MP", "PW", "PR", "AA", "AE", "AP", "VI", "DC"];
		filteredregions = regions.filter(x => blacklist.indexOf(x.value) < 0);
	} else {
		filteredregions = regions;
	}
	return filteredregions;
}
