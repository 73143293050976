import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNotificationsData, fetchMoreNotificationsData } from "../../redux";

// Import Components
import NotificationItem from "../notificationItem/notificationItem";
import MobileNav from "../mobileNav/mobileNav";
import ShowMore from "../common/showMore/showMore";

// Import Images
import ZeroStateImage from "../../img/static/notification-zero-state.svg";
import NotificationLoadingBlock from "../../img/spinners/blocks/notification page.svg";

// Import CSS
import "./notificationPage.css";

class NotificationPage extends Component {
	// When Mount
	async componentDidMount() {
		setTimeout(() => {
			this.props.fetchNotificationsData();

		}, 1000);
		// Populate notifications
	}

	render() {
		const { loading, notifications, loadingMore } = this.props.notificationsData;

		// Check if loading
		if (loading) {
			return (
				<div className="dashboard-wrapper">
					<div className="dashboard-content">
						<img src={NotificationLoadingBlock} alt="Loading..." className="w-100" />
					</div>
				</div>
			);
		}

		// Zero state
		if (notifications.results?.length === 0) {
			return (
				<div className="notifications-content">
					<div className="title">No notifications</div>
					<div className="small mt-4">You’ve got no item to catch up.</div>
					<div className="zero-state-image">
						<img src={ZeroStateImage} alt="Not Available" />
					</div>
				</div>
			);
		}

		// Render
		return (
			<div className="dashboard-wrapper">
				{/* Mobile Nav */}
				<MobileNav title="Notifications" />

				<div className="dashboard-content notifications-page">
					<div className="title d-none d-sm-block">Notifications</div>

					{/* Notification Section */}
					<div className="notification-section">
						<div className="title d-none">Profile</div>

						{notifications.results.map((item) => (
							<NotificationItem key={item.id} notification={item} />
						))}

						<div style={{ maxWidth: 560 }}>
							<ShowMore
								loading={loadingMore}
								handleClick={() => this.props.fetchMoreNotificationsData(notifications.next)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		notificationsData: state.notifications.notificationsData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchNotificationsData: () => dispatch(fetchNotificationsData()),
		fetchMoreNotificationsData: (apiEndpointForMore) => dispatch(fetchMoreNotificationsData(apiEndpointForMore)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
