import React, { Component, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Import Components
import Header from "../header/header";
import Sidebar from "../sideBar/sideBar";
import DashboardPage from "../dashboardPage/dashboardPage";
import FeedPage from "../feedPage/feedPage";
import InvestmentPage from "../investmentPage/investmentPage";
import BankTransferPage from "../bankTransferPage/bankTransferPage";
import SettingsPage from "../settingsPage/settingsPage";
import SupportPage from "../supportPage/supportPage";
import UserProfile from "../userProfile/userProfile";
import NotificationPage from "../notificationPage/notificationPage";

// Import CSS
import "./userHome.css";
import AutomationPage from "../AutomationPage/AutomationPage";
import OneSignal from "react-onesignal";
import store from "../../redux/store";
import { fetchCompanyData } from "../../redux";

const UserHome = () => {

	const [loading, setLoading] = useState(true);

	// Handle Loading
	const handleLoading = () => {
		setLoading(false)
	}

	const subscribeOneSignal = async () => {
		console.log('subscribeOneSignal');
		// await OneSignal.User.PushSubscription.optIn();
		await OneSignal.Notifications.requestPermission();
		// await OneSignal.Slidedown.promptPush();
		const company_email = store.getState()?.user?.userData?.user?.email;
		console.log('company_email_home ', company_email);
		// const company_email = store.getState()?.user?.companyData?.company?.company_email;
		// const user_company = store.getState()?.user?.companyData?.company;
		// console.log('user_company ', user_company);
		// if (company_email) OneSignal.User.addEmail(company_email)
		if (company_email) OneSignal.User.addTag("Email", company_email)

	}

	useEffect(() => {
		setTimeout(() => {
			try {
				subscribeOneSignal();
	
			} catch (err) {
				console.error('subscribeOneSignal_err ', err);
			}
		})
	}, 1000);
	
	// }, [])
	// When Mount
	useEffect(() => {
		// End loading
		handleLoading();
	}, [])

	// Helper Methods
	const handleOpenSideBar = () => {
		document.getElementById("sidebar").classList.remove("d-none");
	};

	const handleCloseSideBar = () => {
		document.getElementById("sidebar").classList.add("d-none");
	};

	// Check if loading
	if (loading) return <div>Loading...</div>;

	return (
		<>
			{/* Header */}
			<Header handleOpen={handleOpenSideBar} />

			{/* Content */}
			<div className="user-home-page-container">
				{/* Side Bar */}
				<Sidebar handleClose={handleCloseSideBar} />

				{/* Body */}
				<div className="body">
					<Routes>
						<Route path="/dashboard/:loansType?" element={<DashboardPage />} />
							
						<Route path="/feed" element={<FeedPage />} />

						<Route path="/loans/:loansType?" element={<InvestmentPage />} />

						<Route path="/bank-transfer" element={<BankTransferPage />} />

						<Route path="/settings" element={<SettingsPage />} />

						<Route path="/support" element={<SupportPage />} />
						
						<Route path="/automation" element={<AutomationPage />} />

						<Route path="/profile" element={<UserProfile />} />

						<Route path="/notification" element={<NotificationPage />} />

						<Route path="/" element={<Navigate to="/user/dashboard" />} replace />
					</Routes>
				</div>
			</div>
		</>
	);

}

export default UserHome;
