import React, { useEffect, useState } from "react";
import OneSignal from 'react-onesignal';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "./redux";

// Google Analytics
import ReactGA from "react-ga";

// Services
// import { onMessageListener } from "./services/firebase-messaging";
import { onPerformanceListener } from "./services/firebase-performance";

// Components
import ProtectedRoute from "./components/common/protectedRoute/protectedRoute";
import HomePage from "./components/homePage/homePage";
import LoginPage from "./components/loginPage/loginPage";
import ForgotPasswordPage from "./components/forgotPasswordPage/forgotPasswordPage";
import SignUpPage from "./components/signUpPage/signUpPage";
import SignUpProcess from "./components/signUpProcess/signUpProcess";
import NotFoundPage from "./components/notFoundPage/notFoundPage";
import Logout from "./components/logout/logout";
import UserHome from "./components/userHome/userHome";
import LNToast from "./components/common/LNToast/LNToast";
import { getCurrentAccount } from "./services/authService";
import { logFirebaseEvent } from "./services/firebase-analytics";
import { updateDeviceInfo } from './services/userService';
import { osName, osVersion, OsTypes } from 'react-device-detect';
import store from "./redux/store";

window.console = {
	log: function() {},
	info: function() {},
	warn: function() {},
	error: function() {}
};

let isOneSignalInitialized = false;

function App() {
	const toast = useSelector((state) => state.general.toast);
	const [oneSignalInitilized, setOneSignalInitilized] = useState(false)
	const dispatch = useDispatch();
	const location = useLocation();

	// Initialize Google Analytics
	const initializeGoogleAnalytics = () => {
		ReactGA.initialize("G-73GJ6K5EXH");
		ReactGA.pageview(window.location.pathname + window.location.search);
	};
	console.log('window.navigator.userAgent ', window.navigator.userAgent)

	const initOneSignal = async () => {

		console.log('oneSignalInitilized ', oneSignalInitilized)
		if (oneSignalInitilized) return;
		
		await OneSignal.init({
			appId: "4f2b8f84-b3ce-4259-b814-720e81ad0400",
			safari_web_id: "web.onesignal.auto.521cdcf4-43b8-4659-a2e2-fd037f95e0d5",
			// notifyButton: {
			// 	enable: true,
			//   },
			// subdomainName: "basil",
		  	//   serviceWorkerParam: {scope: ''}
			//   allowLocalhostAsSecureOrigin: true
			
		}).catch((err) => {
			const error = err ?? 'OneSignal_catch_Err';
			return Promise.reject(error)
		})
		console.log('OneSignal_init')
		setOneSignalInitilized(true);
		// isOneSignalInitialized = true;
		OneSignal.Debug.setLogLevel('trace');

		// OneSignal.login("basil");

		// await OneSignal.User.PushSubscription.optOut()
		// console.log('OneSignal_User_PushSubscription_optOut ')
		// await OneSignal.User.PushSubscription.optIn();
		// .then(async (res) => {
		// 	console.log('OneSignal_User_PushSubscription_optIn ', res)
		// 	const optedIn = OneSignal.User.PushSubscription.optedIn;
		// 	console.log('OneSignal_opted_in ', optedIn)
		// 	if (optedIn) {
		// 		const subscriptionId = OneSignal.User.PushSubscription.id;
		// 		console.log('OneSignal_subscriptionId ', subscriptionId)

		// 		console.log('osName ', osName)
		// 		console.log('osVersion ', osVersion)
		// 		console.log('OsTypes ', OsTypes)


		// 		// if (subscriptionId) {
		// 			const params = {		
		// 				// model: "IntelMac 121",
		// 				os: "Mac OS",
		// 				// device_id: "3224-24564-34fgf-dg4",
		// 				player_id: "4771493a-1283-4dc5-b32c-b18f3c67e586",
		// 				os_version: "17.3.1",
		// 				app_version: "8.0.1",
		// 				app_name: "Lenme Enterprise"				
		// 			}
		// 			// try {
		// 			// 	await updateDeviceInfo(params).then((res) => {
		// 			// 		console.log('updateDeviceInfo_res ', res.data)
		// 			// 	})
		// 			// } catch (err) {
		// 			// 	console.log('updateDeviceInfo_catch_err ', err);
		// 			// }
		// 		// }
		// 	}
		// 	// OneSignal.User.PushSubscription.token
		// 	// OneSignal.User.PushSubscription.id
		// })
	}
	
	useEffect(() => {
		if (!oneSignalInitilized) {
			try {
				// OneSignal.Notifications.
				initOneSignal(); 
				
				
				//   OneSignal.User.addEmail('basil-test-react@test.com')
				//   OneSignal.
				//   OneSignal.Notifications.('basil-test-react@test.com')
				
			
			} catch (e) {
				console.error('OneSignal_init_err ', e);
			}
		}
	}, [oneSignalInitilized]);

	// const runOneSignal = async () => {
	// 	console.log('runOneSignal')
	// 	OneSignal.Debug.setLogLevel('trace');
	// 	await OneSignal.init({ appId: '4f2b8f84-b3ce-4259-b814-720e81ad0400', allowLocalhostAsSecureOrigin: true }).then(async () => {
	// 		console.log('OneSignal_INITIALIZED')
	// 		await OneSignal.Notifications.requestPermission().then((res) => {
	// 			console.log('OneSignal_promptPush_res ', res);
	// 			// OneSignal.
	// 			const pushSubscription = OneSignal.User.PushSubscription;
	// 			console.log('pushSubscription ', pushSubscription)
	// 			const permission = OneSignal.Notifications.permission
	// 			console.log('permission ', permission)
	// 		}).catch((err) => {
	// 			console.log('OneSignal_promptPush_err ', err);
	// 		})
	// 		// do other stuff
	// 	}).catch((err) => {
	// 		return Promise.reject(err);
	// 	})
	// 	// OneSignal.Slidedown.promptPush();
	// }

	const handleNotificationPermissionChanged = async (e) => {
		console.log('handleNotificationPermissionChanged_e ', e);
		if (e?.current?.optedIn && e?.current?.token) {
			const subscriptionId = e?.current?.id;
			const pushToken = e?.current?.token
			console.log('OneSignal_subscriptionId ', subscriptionId)
			console.log('OneSignal_pushToken ', pushToken)

			console.log('osName ', osName)
			console.log('osVersion ', osVersion)
			console.log('OsTypes ', OsTypes)
			if (!subscriptionId) return;

			const userEmail = store.getState()?.user?.userData?.user?.email;
			console.log('company_email_App ', userEmail)
			// if (userEmail) OneSignal.User.addEmail(userEmail);
			if (userEmail) OneSignal.User.addTag("Email", userEmail)

			const userDataId = store.getState()?.user?.userData?.user?.id
			console.log('userDataId ', userDataId);

			const last12PushToken = pushToken?.slice(-12);
			console.log('last12PushToken ', last12PushToken);
			
			// if (subscriptionId) {
			const params = {		
				model: osName,
				// os: "Mac OS",
				os: osName,
				device_id: subscriptionId + "-" + userDataId + last12PushToken,
				player_id: subscriptionId,
				// os_version: "17.3.1",
				os_version: osVersion,
				app_version: "8.0.1",
				app_name: "Lenme Enterprise"				
			}
			console.log('params ', params)
			try {
				await updateDeviceInfo(params).then((res) => {
					console.log('updateDeviceInfo_res ', res.data)
				})
			} catch (err) {
				console.log('updateDeviceInfo_catch_err ', err);
			}
			// }
		}
	}

	useEffect(() => {
		OneSignal.User.PushSubscription.addEventListener("change", handleNotificationPermissionChanged)

		return () => {
			OneSignal.User.PushSubscription.removeEventListener("change", handleNotificationPermissionChanged)
		}
	}, [])

	// When Mount
	useEffect(() => {
		// Initialize google analytics
		initializeGoogleAnalytics();

		// eslint-disable-next-line no-console
	}, []);

	// Firebase Cloud Messaging
	// try {
	// 	onMessageListener()
	// 		.then((payload) => {
	// 			const { title, body } = payload.notification;
	// 			dispatch(showToast("", title, body));
	// 		})
	// 	// Firebase Performance Monitoring
	// 	onPerformanceListener();
	// } catch (err) {
	// 	console.log('firebase_err ', err);
	// }

	useEffect(() => {
		console.log(`The current route is ${location.pathname}`);
		logFirebaseEvent('screen_view', {
			firebase_screen: location.pathname + location.search,
    		firebase_screen_class: location.pathname + location.search,
		});
	}, [location])

	// Render
	return (
		<main>
			<Routes>
				{/* Pre-login */}
				<Route path="/signup" element={<SignUpPage />} />

				<Route path="/forgot-password" element={<ForgotPasswordPage />} />
					
				<Route path="/login" element={<LoginPage />} />

				<Route path="/" element={<HomePage />} />

				{/* Post-login */}
				<Route
					path="/signup-process"
					element={
					<ProtectedRoute>
						<SignUpProcess />
					</ProtectedRoute>
					}
				/>

				<Route
					path="/user/*"
					element={
					<ProtectedRoute>
						<UserHome />
					</ProtectedRoute>
					}
				/>

				<Route
					path="/logout"
					element={
					<ProtectedRoute>
						<Logout />
					</ProtectedRoute>
					}
				/>
				
				{/* Not Found */}
				<Route path="/not-found" element={<NotFoundPage />} />
					
				<Route path="/*" element={<Navigate to="/not-found" />} replace />
			</Routes>

			{/* Toasts */}
			{toast?.length > 0 && (
				<div className="toast-container">
					{toast.map((item, index) => (
						<LNToast
							key={index}
							index={index}
							type={item.type}
							title={item.title}
							body={item.body}
							button={item.button}
							onButtonClick={item.onButtonClick}
						/>
					))}
				</div>
			)}
		</main>
	);
}

function RequireAuth({ children, redirectTo }) {
	let isAuthenticated = getCurrentAccount();
	return isAuthenticated ? children : <Route element={<Navigate to={redirectTo} />} />;
  }

export default App;
