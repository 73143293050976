import React, { useEffect, useRef, useState } from 'react'

// src/img/assets/blank-frame.svg
import BlankFrame from '../../img/assets/blank-frame.svg'
import SearchIcon from '../../img/icons/search-automation-icon.svg'

// Import CSS
import "./AutomationPage.css";
import MobileNav from '../mobileNav/mobileNav';
import AutomationCondition from './AutomationCondition';
import { getAutomationRules, postAutomationRules, putAutomationRules } from '../../services/automationService';
import AutomationRuleCard from './AutomationRuleCard';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';
import { Slider, sliderClasses } from '@mui/base/Slider';
import { alpha } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';
import { getUser } from '../../services/userService';
import autoAnimate from '@formkit/auto-animate';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import FlipMove from 'react-flip-move';

function AutomationPage() {

  const [loanAutomations, setLoanAutomations] = useState([]);
  const [canCreateAutomationRule, setCanCreateAutomationRule] = useState(false);
  const [activeLoanAutomations, setActiveLoanAutomations] = useState([]);
  const [inactiveLoanAutomations, setInactiveLoanAutomations] = useState([]);
  const [editing, setEditing] = useState(false);
  const [ruleName, setRuleName] = useState('');
  const [conditionMin, setConditionMin] = useState();
  const [conditionMax, setConditionMax] = useState();
  const [offerInterest, setOfferInterest] = useState(0.03);
  
  const [selectedLoanType, setSelectedLoanType] = useState("cash");
  const LoanTypes = ['both', 'cash', 'crypto'];
  const LoanTypesVisible = ['Any Loans', 'Cash Loans', 'Crypto Loans'];
  const [acceptedAutoDeployFund, setAcceptedAutoDeployFund] = useState(false);
  const [editingView, setEditingView] = useState(
    {
      creatNewAutomationRule: false,
      EditAutomationRule: false,
    }
  );

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(1)
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAutomations, setFilteredAutomations] = useState([]);
  
  // const [conditionOptions, SetConditionOptions] = useState(['Hard Inquiry', 'Hard Inquiry']);
  const [conditions, setConditions] = useState(
    [{
      id: uuidv4(),
      num: 1,
      conditionOption: null,
      options: {   
        // min_credit_score: null,
        // max_credit_score: null,
        // min_payback_period: null,
        // max_payback_period: null,
        // min_number_of_previous_loans: null,
        // max_number_of_previous_loans: null,
        // min_payment_history: null,
        // max_payment_history: null,
        // min_credit_utlization: null,
        // max_credit_utlization: null,
        // min_hard_inquiry: null,
        // max_hard_inquiry: null,
        // min_total_accounts: null,
        // max_total_accounts: null,
        // min_annual_income: null,
        // max_annual_income: null,
        // min_deregatory_mark: null,
        // max_deregatory_mark: null,
        // min_lenmo_score: null,
        // max_lenmo_score: null,
        // LenmePrediction: null,
        // min_lenme_predict_confidence: null, 
        // max_lenme_predict_confidence: null,
        // min_avg_credit: null, 
        // max_avg_credit: null, 
        // min_avg_debit: null, 
        // max_avg_debit: null, 
      }
    }]
  );

  // 14 options and not reusable so we need to remove the used ones from the options array
  const initialOptions = [
    { value: 'credit_score', label: 'Credit score', id: 1, min: 350, max: 850 },
    { value: 'payback_period', label: 'Payback period', id: 2, min: 1, max: 12 },
    { value: 'number_of_previous_loans', label: 'Number of previous loans', id: 3, min: 1, max: '9+' },
    { value: 'payment_history', label: 'Payment history', id: 4, min: 0, max: 100 },
    { value: 'credit_utlization', label: 'Credit utlization', id: 5, min: 0, max: 100 },
    { value: 'hard_inquiry', label: 'Hard inquiry', id: 6, min: 0, max: '9+' },
    { value: 'total_accounts', label: 'Total accounts', id: 7, min: 0, max: '9+' },
    { value: 'annual_income', label: 'Annual income', id: 8, min: 0, max: '150k+' },
    { value: 'deregatory_mark', label: 'Deregatory mark', id: 9, min: 0, max: '9+' },
    { value: 'lenmo_score', label: 'Lenme score', id: 10, min: 0, max: 100 },
    { value: 'LenmePrediction', label: 'Lenme Prediction', id: 11, LenmePrediction: 'Likely to Pay' },
    { value: 'lenme_predict_confidence', label: 'Lenme predict confidence', id: 12, min: 0, max: 100 },
    { value: 'avg_credit', label: 'Avg credit', id: 13, min: 0, max: '5000+' },
    { value: 'avg_debit', label: 'Avg debit', id: 14, min: 0, max: '5000+' },
    { value: 'salus_score', label: 'Salus Score', id: 15, min: 0, max: 100 },
    { value: 'loan_amount', label: 'Loan Amount', id: 16, min: 0, max: selectedLoanType === 'cash' ? 5000 : 10000 },
  ];

  const [conditionOptions, setConditionOptions] = useState([...initialOptions]);
  const [selectedConditionOptions, setSelectedConditionOptions] = useState([]);
  const [showSalusTOS, setShowSalusTOS] = useState(false);
  
  const [editExistingRule, setEditExistingRule] = useState(false)
  const [editExistingRuleId, setEditExistingRuleId] = useState(null)
  const [editingExistConditions, setEditingExistConditions] = useState(null)  
  const [refresh, setRefresh] = useState(true)
  const [errors, setErrors] = useState([])
  const [isLoanAutomationsLoading, setIsLoanAutomationsLoading] = useState(true)

  const handleSelectChange = (selectedOption, conditionNum, id) => {
    // // Update state for the second react-select
    // setConditionOptions((prevOptions) =>
    //   prevOptions.filter((option) => option.id !== selectedOption.id)
    // );
    console.log('handleSelectChange_selectedOption ', selectedOption)
    console.log('handleSelectChange_selectedOption_Id ', selectedOption.id)
    console.log('handleSelectChange_ruleNum ', conditionNum)
    // const targetCondition = conditions;
    // console.log('targetCondition ', targetCondition)
    
    // Update state for the selected option in the specified rule
    setConditions((prevConditions) =>
      prevConditions.map((rule) =>
        rule.num === conditionNum
          ? { ...rule, conditionOption: selectedOption.value }
          : rule
      )
    );

    // Remove the selected option from the available options ;; for the other conditions only
    // setConditionOptions((prevOptions) =>
    //   prevOptions.filter((option) => option.value !== selectedOption.value)
    // );

    // check if the value already exist if it does remove it
    // if (selectedConditionOptions.length > 0) {

    //   setSelectedConditionOptions((prevSelectedCondition) =>
    //     prevSelectedCondition.map((condition) =>
    //       condition.id === selectedOption.id
    //         ? { ...condition, value: selectedOption.value }
    //         : condition
    //     )
    //   );

    // } else {

    //   setSelectedConditionOptions((prevSelectedCondition) => [...prevSelectedCondition, selectedOption.value])
    // }

    // Check if selectedOption.id exists in the prevSelectedCondition array
    setSelectedConditionOptions((prevSelectedCondition) => {
      let updatedConditions = prevSelectedCondition.map((condition) => {
         if (condition.id === id) {
           return { ...condition, value: selectedOption.value };
         } else {
           return condition;
         }
      });
     
      // Check if selectedOption.id exists in the updatedConditions array
      if (!updatedConditions.some(condition => condition.id === id)) {
         // If it doesn't exist, add the new value
         updatedConditions.push({ id: id, value: selectedOption.value });
      }
     
      return updatedConditions;
     });

     if (selectedOption.id === 15) setShowSalusTOS(true);

    // setConditions((prevConditions) => [...prevConditions, {num: targetCondition?.num, conditionOption: selectedOption}])

  };

  const handleUpdateOptions = (ruleNum, newOptions) => {
    // setConditions((prevConditions) => {
    //   const updatedConditions = prevConditions
    //     .filter((condition) => condition.num === ruleNum)
    //     .map((condition, index) => ({ ...condition, options: {...condition.options, ...newOptions} }));
  
    //   return [...updatedConditions];  // Create a new array reference
    // });
    setConditions((prevConditions) => {
      return prevConditions.map((condition) => {
         if (condition.num === ruleNum) {
           return { ...condition, options: { ...condition.options, ...newOptions } };
         }
         return condition;
      });
     });
    };

  const handleChangeOptions = (options, conditionNum, id, selectedOptionValue) => {

    console.log('handleChangeOptions_options ', options);
    console.log('handleChangeOptions_conditionNum ', conditionNum);
    console.log('handleChangeOptions_selectedOptionValue ', selectedOptionValue);
    if (!options && selectedOptionValue) {
      setConditions((prevConditions) => {
        return prevConditions.map((condition) => {
          if (condition.id === id) {
            const updatedOptions = { ...condition.options };
            console.log('updatedOptions ', updatedOptions)
            console.log('updatedOptions[selectedOptionValue] ', updatedOptions[selectedOptionValue])
            if (selectedOptionValue === 'all') return { ...condition, options: {} };
            delete updatedOptions[selectedOptionValue];
            return { ...condition, options: updatedOptions };
          }
          return condition;
        });
      });
    }

    else if (options?.min) {

      console.log('options.min')
      let OptionName = null;
      const targetCondition = conditions.findIndex((condition) => condition.num === conditionNum);
      if (targetCondition !== -1 && conditions[targetCondition].conditionOption != null) {
        const keys = Object.keys(options);
        OptionName = keys[0] + "_" + conditions[targetCondition].conditionOption
      }
      console.log('OptionName ', OptionName)
      if (OptionName != null) {

        handleUpdateOptions(conditionNum, { [OptionName]: options?.min });
      }
    }
    else if (options?.max) {
      console.log('options.max')
  
      let OptionName = null;
      const targetCondition = conditions.findIndex((condition) => condition.num === conditionNum);
      if (targetCondition !== -1 && conditions[targetCondition].conditionOption != null) {
        const keys = Object.keys(options);
        OptionName = keys[0] + "_" + conditions[targetCondition].conditionOption
      }
      console.log('OptionName ', OptionName)
      if (OptionName != null) {

        handleUpdateOptions(conditionNum, { [OptionName]: options?.max });
      }
    }

    else if (options?.LenmePrediction) {
      console.log('options.LenmePrediction')
      handleUpdateOptions(conditionNum, { LenmePrediction: options?.LenmePrediction });
    }

  }
  

  const handleAddCondition = (conditionNum) => {
    console.log('conditionNum ', conditionNum)
    // Calculate the next num value based on the length of the conditions array
    const nextNum = conditions.length + 1;
    // setConditions(conditions[conditionNum]?.num + 1)
    // Append a new condition to the existing array
    setConditions(prevConditions => [
      ...prevConditions,
      { 
        id: uuidv4(),
        num: nextNum, 
        conditionOption: null
      }  // You can set the desired value for 'num' for the new condition
    ]);
  }

  const handleRemoveCondition = (removedOption, ruleNum, id) => {
    // console.log('handleRemoveCondition_index ', index)
    console.log('removedOption ', removedOption)
    console.log('removedOption_id ', id)
    // TODO Don't add the same value multiple times to the condition Options
    // Add the removed option back to the available options
    // setConditionOptions((prevOptions) => [...prevOptions, removedOption]);
    setSelectedConditionOptions((prevOptions) => 
      prevOptions.filter((selectedCondition) => 
          selectedCondition.value !== removedOption?.value
      )
    )

    if (removedOption?.id === 15) setShowSalusTOS(false);
    // Remove the conditionOption for the specific rule
    // setConditions((prevConditions) =>
    //   prevConditions.map((condition) =>
    //     condition.num === ruleNum ? { ...condition, conditionOption: null } : condition
    //   )
    // );

    // Remove the entire condition for the specific rule
    // setConditions((prevConditions) => prevConditions.filter((condition) => condition.num !== ruleNum));
    // setConditions((prevConditions) => {
    //   console.log('prevConditions ', prevConditions)
    //   const updatedConditions = prevConditions
    //     .filter((condition) => condition.num !== ruleNum)
    //     .map((condition, index) => ({ ...condition, num: index + 1 }));
    //     console.log('updatedConditions ', updatedConditions)
    //   return [...updatedConditions];  // Create a new array reference
    // });

    setConditions((prevConditions) => {
      console.log('prevConditions ', prevConditions)
      const updatedConditions = [];
      const updatedConditionsAfterIndex = [];
      prevConditions.forEach((condition, index) => {
         if (condition.id !== id) {
           updatedConditions.push({ ...condition, num: index + 1 });
         }
      });

      updatedConditions.forEach((condition, index) => {
        updatedConditionsAfterIndex.push({ ...condition, num: index + 1 });
      });

      console.log('updatedConditions ', updatedConditions)
      console.log('updatedConditionsAfterIndex ', updatedConditionsAfterIndex)
      return updatedConditionsAfterIndex;
     });

    // Find the index of the condition to be removed
    // const removedIndex = conditions.indexOf(removedOption.id);

    // if (removedIndex !== -1) {
    //   // Remove the condition based on its index
    //   setConditions((prevConditions) => {
    //     const newConditions = [...prevConditions];
    //     newConditions.splice(removedIndex, 1);
    //     return newConditions;
    //   });
    // }
    // setTimeout(() => {

    //   console.log('conditions ', conditions)
    // }, 2000)

    // Handle other logic for removing a condition if needed
  };
  
  const handleEditExistingRule = (id) => {
    const ruleIndex = loanAutomations.findIndex((predicate) => predicate.id === id)
    if (ruleIndex !== -1) {
      const targetRuleObj = loanAutomations[ruleIndex];
      // setRuleName(targetRule?.rule_name);
      // setOfferInterest(targetRule?.offered_interest);
      // setR
      console.log('targetRuleObj_before ', targetRuleObj)
      Object.keys(targetRuleObj).forEach(key => {
        if (targetRuleObj[key] === null) {
           delete targetRuleObj[key];
        }
      });
      console.log('targetRuleObj_after ', targetRuleObj)

      // get all conditions for that rule
      let filteredObj = Object.keys(targetRuleObj)
        .filter(key => key.startsWith('min_') || key.startsWith('max_') || key === 'LenmePrediction')
        .reduce((newObj, key) => {
            newObj[key] = targetRuleObj[key];
            return newObj;
        }, {});
      // console.log('filteredObj', filteredObj)
      const options = filteredObj;

      let conditionOptions = filteredObj;
      let numOfConditions = 0;

      let keyNames = Object.keys(conditionOptions).map(key => key.replace(/^min_|^max_/, ''));

      let keyNames_after_rm_duplicates = [];
      keyNames.forEach(key => {
        if (!keyNames_after_rm_duplicates.includes(key)) {
          keyNames_after_rm_duplicates.push(key);
        }
      });
      numOfConditions = keyNames_after_rm_duplicates.length
      // if (keyNames.length % 2 === 0) {
      //   console.log('no lenme predic');
      //   numOfConditions = keyNames.length / 2;
      // } else {
      //     console.log('there is lenme predic');
      //     numOfConditions = Math.trunc(keyNames.length / 2) + 1;
      // }
      console.log('conditionOptions ', conditionOptions)
      console.log('keyNames ', keyNames)
      console.log('keyNames_after_rm_duplicates ', keyNames_after_rm_duplicates)
      console.log('numOfConditions ', numOfConditions)

      // let newConditions = Array(numOfConditions).fill().map((_, i) => ({
      //   id: uuidv4(),
      //   num: i + 1,
      //   conditionOption: keyNames_after_rm_duplicates[i],
      //   options: conditionOptions
      // }));

      let newConditions = keyNames_after_rm_duplicates.map((keyName, i) => {
        let _options = {}
        if (keyName === 'LenmePrediction'){
          _options = {
            LenmePrediction: conditionOptions['LenmePrediction']
          }
        } else {
          _options = {
            [`min_${keyName}`]: Math.trunc(conditionOptions[`min_${keyName}`]),
            [`max_${keyName}`]: Math.trunc(conditionOptions[`max_${keyName}`])
          }
        }

        return {
          id: uuidv4(),
          num: i + 1,
          conditionOption: keyName,
          options: {..._options}
        }
      })

      console.log('newConditions2 ', newConditions)
      setConditions(newConditions);
      setEditExistingRuleId(id);
      // setConditions() 
      // Edit the rule conditions
      setRuleName(targetRuleObj?.rule_name)
      setSelectedLoanType(targetRuleObj?.loan_type)
      setAcceptedAutoDeployFund(targetRuleObj?.automate_fund)
      setEditingExistConditions(targetRuleObj)
      setOfferInterest(targetRuleObj?.offered_interest)
      setEditExistingRule(true)
    }
    else {
      console.log('handleEditExistingRule: ruleIndex === -1')
    }
  }
  
  const getLoanAutomations = async () => {

    // try {

    //   const res = await getAutomationRules();
    //   console.log('getLoanAutomation_res ', res.data);

    //   if (res.data.results.length === 0) {
    //     setLoanAutomations(0)
    //   }

    // } catch (err) {
    //   console.log('getLoanAutomation_err ', err);
    // }
    // setLoading(true);    
    try {
      await getAutomationRules().then((res) => {
        console.log('getAutomationRules_SUCCESS ', res?.status)
        console.log('getAutomationRules_SUCCESS_res ', res.data.results)
        const results = res.data.results
        setLoanAutomations(results);
        const _activeLoanAutomations = results?.filter(loanAutomation => loanAutomation.rule_status === "active")
        const _inActiveLoanAutomations = results?.filter(loanAutomation => loanAutomation.rule_status === "inactive")
        console.log('_activeLoanAutomations ', _activeLoanAutomations)
        console.log('_inActiveLoanAutomations ', _inActiveLoanAutomations)

        setActiveLoanAutomations(_activeLoanAutomations)
        setInactiveLoanAutomations(_inActiveLoanAutomations)
        // setTimeout(() => {

        //   setIsLoanAutomationsLoading(true);
        // }, 600)
        

        setLoading(false);
        setRefresh(false);
        setTimeout(() => {

          setIsLoanAutomationsLoading(false);
        }, 1000)
      })
    } catch (err) {
      console.log('getAutomationRules_CATCH_ERR ', err)
      setLoading(false);
      setRefresh(false);
      setIsLoanAutomationsLoading(false);
    }
  }

  const getUserData = async () => {
    try {
      await getUser().then((res) => {
        console.log('getUser_SUCCESS ', res?.status)
        console.log('getUser_SUCCESS_res ', res?.data)

        setCanCreateAutomationRule(res?.data?.can_create_automation_rules)

      })
    } catch (err) {
      console.log('getUser_CATCH_ERR ', err)
    }
  }

  const getData = async () => {
    await getUserData();
    await getLoanAutomations();
  }
  
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        getData();
      }, 2000);
    }

  }, [refresh])

  // useEffect(() => {

  //   console.log('useEffect_conditionOptions ', conditionOptions)
  //   console.log('useEffect_conditions ', conditions)
  //   console.log("loanAutomations ", loanAutomations)
  //   console.log("editing ", editing)
  //   console.log("editExistingRule ", editExistingRule)
  //   console.log("loading ", loading)
  //   console.log("selectedConditionOptions ", selectedConditionOptions)
    
  // }, [conditionOptions, conditions, loanAutomations, editing, editExistingRule, loading, selectedConditionOptions])

  useEffect(() => {

    console.log('filteredAutomations ', filteredAutomations)
    console.log('activeLoanAutomations ', activeLoanAutomations)
    console.log("inactiveLoanAutomations ", inactiveLoanAutomations)
    
  }, [filteredAutomations, activeLoanAutomations, inactiveLoanAutomations])

  useEffect(() => {
    if (searchTerm) {

      console.log('searchTerm ', searchTerm);
      const filteredResult = searchLoanAutomations(searchTerm);
      console.log('filteredResult ', filteredResult);
  
      setFilteredAutomations(filteredResult);
    } else {
      console.log('reset_filteredResult');
      setFilteredAutomations([]);
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   if (activeParentRef.current) {
  //     autoAnimate(activeParentRef.current, {duration: 1500, easing: 'ease-out'});
  //   }
  // }, [loanAutomations, filteredAutomations, activeLoanAutomations, inactiveLoanAutomations]); // Re-run the effect when posts state changes


  const handleCreateAutomationRule = () => {
    console.log('handleCreateAutomationRule')
    setEditingView({ creatNewAutomationRule: true });
    setEditing(true);

  }

  const handleSliderChange = (value) => {
    console.log('handleSliderChange_value ', value)
		setOfferInterest(value / 100);

	};

  const handleSaveRules = async () => {
    console.log('handleSaveRules')

    // const saved_conditions = conditions.map((val) => {
    //   return {conditionOption: val.conditionOption, options: val.options}
    // })
    // if (conditions[0].conditionOption == null) return
    // const LoanTypes = ['Any Loans', 'Cash Loans', 'Crypto Loans'];
    // let loan_type = 'both';
    // let loan_type = selectedLoanType === 'Any Loans' ? 'Both' : 
    // if (selectedLoanType === LoanTypes[0]) loan_type = 'both';
    // else if (selectedLoanType === LoanTypes[1]) loan_type = 'cash';
    // else if (selectedLoanType === LoanTypes[2]) loan_type = 'crypto';

    console.log('saved_conditions ', conditions)

    const conditionOptions = conditions
      .map((condition) => condition.conditionOption)
      .filter((option) => option != null);
    console.log('conditionOptions ', conditionOptions)
    
    const options = conditions
      .map((condition) => condition.options)
      .filter((option) => option != null);
    console.log('options ', options)

    // const filteredOptions = options.map(condition => ({
    //   ...condition,
    //   options: Object.entries(condition).reduce((acc, [key, value]) => {
    //     if (value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // }));

    // console.log('filteredOptions ', filteredOptions)

    const params_options = options.map(filteredOption => filteredOption);
    const mergedParamsOptions = params_options.reduce((accumulator, currentValue) => {
      return { ...accumulator, ...currentValue };
     }, {});
    
    const params = {
      rule_name: ruleName,
      offered_interest: offerInterest,
      loan_type: selectedLoanType,
      automate_fund: acceptedAutoDeployFund,
      ...mergedParamsOptions
    }

    console.log('params ', params)

    console.log('editExistingRuleId ', editExistingRuleId)
    
    // TODO: uncomment
    if(editExistingRule && editExistingRuleId) {
      try {
        await putAutomationRules(editExistingRuleId, params).then((res) => {
          console.log('putAutomationRules_SUCCESS ', res?.status)
          handleCancelRules();
          
          setRefresh(true);
        })
      } catch (err) {
        console.log('putAutomationRules_CATCH_ERR ', err?.response?.data)
        // setRefresh(true);
        setErrors(err?.response?.data)
      }
    }
    else {

      try {
        await postAutomationRules(params).then((res) => {
          console.log('postAutomationRules_SUCCESS ', res?.status)
          handleCancelRules();
          setRefresh(true);
        })
      } catch (err) {
        console.log('postAutomationRules_CATCH_ERR ', err?.response?.data)
        // setRefresh(true);
        setErrors(err?.response?.data)
      }
    }
  }

  const handleCancelRules = () => {
    // Reset Editing states
    console.log('handleCancelRules');
    setEditing(false);
    setEditExistingRule(false);
    setEditExistingRuleId(null);
    setRuleName('');
    setSelectedLoanType("cash");
    setAcceptedAutoDeployFund(false);
    setOfferInterest(0.03);
    setConditions(
      [{
      id: uuidv4(),
      num: 1,
      conditionOption: null,
      options: {}
      }]
    )
    setSelectedConditionOptions([])
    setShowSalusTOS(false);
  }

  const refreshRules = () => {
    setRefresh(true);
  }

  /**
   * 
   * @param {string} term 
   * Search Loan Automations.
   * @returns {string[]}
   */
  const searchLoanAutomations = (term) => {
    // All
    if (tabValue === 1) return loanAutomations.filter(automation => automation.rule_name.toLowerCase().includes(term.toLowerCase()));
    // Active
    if (tabValue === 2) return activeLoanAutomations.filter(automation => automation.rule_name.toLowerCase().includes(term.toLowerCase()));
    // Inactive
    if (tabValue === 3) return inactiveLoanAutomations.filter(automation => automation.rule_name.toLowerCase().includes(term.toLowerCase()));
    
  }

  const resetTabsStates = () => {
    console.log('resetTabsStates')
    setFilteredAutomations([]);
    setSearchTerm("");
    setIsLoanAutomationsLoading(true);

    setTimeout(() => {

      setIsLoanAutomationsLoading(false);
    }, 400)
  }

  const renderLoanAutomationCard = () => {

    if (filteredAutomations.length === 0) {
      return (
        <FlipMove 
          // staggerDurationBy="20"
          duration={500}
          enterAnimation="fade"
          leaveAnimation="accordionVertical"
          typeName="div"
          style={{margin: 0, padding: 0}}
        >

          {loanAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
            // <div key={i} style={{marginBottom: '16px'}}>

            <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
              ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
              handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
              offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
              onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
            />
            // </div>
          ))
          // {testArr ? testArr.map((testArray, i) => (
          //   <div key={testArray.id} style={{marginBottom: '16px', width: '300px', height: '100px', background: testArray.bg}}>
          //     <button onClick={() => {setTestArr((prev) => prev.splice(i))}}>delete</button>
          //     {/* <AutomationRuleCard id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
          //       ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
          //       handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
          //       offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
          //       onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
          //     /> */}
          //   </div>
          // ))
          }
        </FlipMove>
      )
    }
    else return (
      <FlipMove
        // staggerDurationBy="20"
        duration={500}
        enterAnimation="fade"
        leaveAnimation="accordionVertical"
        typeName="div"
        style={{margin: 0, padding: 0}}
      >
        {filteredAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (

          <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
            ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
            handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
            offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
            onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
          />
        ))}
      </FlipMove>
    )
  }

  if (loading) return <div></div>
  return (
    <div className='dashboard-wrapper' style={{background:'white'}} >
      {/* Mobile Nav */}
      <MobileNav title="Automation" />

      <div className='dashboard-content'> 

      
        {!editExistingRule && !editing && loanAutomations.length > 0 && (

          <div className='container-editing-automation'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',  marginBottom: '26px'}} >
              <div style={{}} className='automation-title'>Automations</div>
              <button disabled={!canCreateAutomationRule} onClick={ () => handleCreateAutomationRule() } type='button' style={{}} className='automation-title-button' >
                {/* <span>&times;</span> */}
                <p style={{margin: 'auto', fontSize: '14px', fontWeight: 600, fontStyle: 'normal'}} >Create an automation</p> 
              </button>        
            </div>
            <div>
              <Tabs onChange={(e, value) => {
                  e.preventDefault();
                  setTabValue(value);
                  resetTabsStates();
                 }} 
                style={{background:'', border: 'none', outline: 'none',}} defaultValue={1} >
                <TabsList style={{ borderBottomWidth: '1px', borderBottomColor: '#DCDDDE', borderBottomStyle: 'solid', outline: 'none', marginBottom: '12px', flex: 1}} className="">
                  <Tab style={{
                    borderBottomColor: tabValue === 1 ? '#7652FF' : '',
                    borderBottomWidth: tabValue === 1 ? '1px' : '',
                    borderBottomStyle: tabValue === 1 ? 'solid' : '',
                    marginRight: '32px',
                    color: tabValue === 1 ? '#7652FF' : '#1A1A1A',
                  }} 
                  className="tab-item " value={1}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                      <div style={{marginRight: '5px'}}>All</div>
                      <div style={{background: '#1A1A1A', borderRadius: '100px', width: '24px', height: '16px', textAlign: 'center',}}>
                        <p style={{color:'#FFF', fontSize: '12px', fontWeight: 500}}>{filteredAutomations.length > 0 ? filteredAutomations.length : loanAutomations.length}</p>
                      </div>
                    </div>
                  </Tab>
                  <Tab style={{
                    borderBottomColor: tabValue === 2 ? '#7652FF' : '',
                    borderBottomWidth: tabValue === 2 ? '1px' : '',
                    borderBottomStyle: tabValue === 2 ? 'solid' : '',
                    marginRight: '32px',
                    color: tabValue === 2 ? '#7652FF' : '#1A1A1A',
                  }} 
                  className="tab-item " value={2}>
                    Active
                  </Tab>
                  <Tab style={{
                    borderBottomColor: tabValue === 3 ? '#7652FF' : '',
                    borderBottomWidth: tabValue === 3 ? '1px' : '',
                    borderBottomStyle: tabValue === 3 ? 'solid' : '',
                    color: tabValue === 3 ? '#7652FF' : '#1A1A1A',
                    }} 
                    className="tab-item" value={3}
                  >
                    Inactive
                  </Tab>
                </TabsList>
                <TabPanel className="" value={1}>
                  <div>
                    <div className="white-search-bar-automation" style={{ marginBottom: '40px'}}>
                      <input
                        className="white-search-bar-input " 
                        type="text"
                        value={searchTerm}
                        placeholder="Search for an automation"
                        onChange={(e) => {
                          // this.searchProviders(e.target.value);
                          setSearchTerm(e.target.value);
                        }}
                      />
                      {/* <div className='search-icon'> */}
                      <img src={SearchIcon} alt="search icon" className='search-icon' />
                      {/* </div> */}
                    </div>

                    {isLoanAutomationsLoading ? (
                      [...Array(loanAutomations.length)].map((_, i) => (
                        // <div key={i} style={{marginBottom: '16px'}}>
                          <AutomationRuleCard key={i} isSkeleton={true} />
                        // </div>
                      ))
                    )
                    :
                    // renderLoanAutomationCard()
                    // <FlipMove appearAnimation='fade' enterAnimation="fade" leaveAnimation="accordionVertical">
                    <FlipMove 
                      staggerDurationBy="20"
                      duration={500}
                      enterAnimation="elevator"
                      leaveAnimation="elevator"
                      typeName="div"
                      style={{margin: 0, padding: 0}}
                    >

                      {filteredAutomations.length === 0 ? loanAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                        // <div key={i} style={{marginBottom: '16px'}}>

                        <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                          ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                          handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                          offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                          onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                        />
                        // </div>
                      ))
                      // {testArr ? testArr.map((testArray, i) => (
                      //   <div key={testArray.id} style={{marginBottom: '16px', width: '300px', height: '100px', background: testArray.bg}}>
                      //     <button onClick={() => {setTestArr((prev) => prev.splice(i))}}>delete</button>
                      //     {/* <AutomationRuleCard id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                      //       ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                      //       handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                      //       offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                      //       onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                      //     /> */}
                      //   </div>
                      // ))
                      : 
                        filteredAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                          // <div key={loanAutomation.id + `${i}`} style={{marginBottom: '16px'}}>

                          <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                            ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                            handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                            offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                            onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                          />
                          // </div>
                        ))
                      }
                    </FlipMove>
                    }
                  </div>
                </TabPanel>
                <TabPanel className="" value={2}>
                  <div>
                    <div className="white-search-bar-automation" style={{ marginBottom: '40px'}}>
                      <input
                        className="white-search-bar-input " 
                        type="text"
                        value={searchTerm}
                        placeholder="Search for an automation"
                        onChange={(e) => {
                          // this.searchProviders(e.target.value);
                          setSearchTerm(e.target.value);
                        }}
                      />
                      {/* <div className='search-icon'> */}
                      <img src={SearchIcon} alt="search icon" className='search-icon' />
                      {/* </div> */}
                    </div>

                    {isLoanAutomationsLoading ? (
                      [...Array(activeLoanAutomations.length)].map((_, i) => (
                        // <div key={i} style={{marginBottom: '16px'}}>
                          <AutomationRuleCard key={i} isSkeleton={true} />
                        // </div>
                      ))
                    )
                    :
                      <FlipMove
                        staggerDurationBy="20"
                        duration={500}
                        enterAnimation="elevator"
                        leaveAnimation="elevator"
                        typeName="div"
                        style={{margin: 0, padding: 0}}
                      >

                        {filteredAutomations.length === 0 ? activeLoanAutomations.length > 0 && activeLoanAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                          // <div key={loanAutomation.id + `${i}`} style={{marginBottom: '16px'}}>

                          <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                            ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                            handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                            offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                            onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                          />                      
                          // </div>
                        ))
                        :
                          filteredAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                            // <div key={loanAutomation.id + `${i}`} style={{marginBottom: '16px'}}>

                            <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                              ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                              handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                              offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                              onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                            />                      
                            // </div>
                          ))
                        }
                      </FlipMove>
                    }
                  </div> 
                </TabPanel>
                <TabPanel className="" value={3}>
                  <div>
                    <div className="white-search-bar-automation" style={{ marginBottom: '40px'}}>
                      <input
                        className="white-search-bar-input " 
                        type="text"
                        value={searchTerm}
                        placeholder="Search for an automation"
                        onChange={(e) => {
                          // this.searchProviders(e.target.value);
                          setSearchTerm(e.target.value);
                        }}
                      />
                      {/* <div className='search-icon'> */}
                      <img src={SearchIcon} alt="search icon" className='search-icon' />
                      {/* </div> */}
                    </div>

                    {isLoanAutomationsLoading ? (
                      [...Array(inactiveLoanAutomations.length)].map((_, i) => (
                        // <div key={i} style={{marginBottom: '16px'}}>
                          <AutomationRuleCard key={i} isSkeleton={true} />
                        // </div>
                      ))
                    )
                    :
                      <FlipMove 
                        staggerDurationBy="20"
                        duration={500}
                        enterAnimation="elevator"
                        leaveAnimation="elevator"
                        typeName="div"
                        style={{margin: 0, padding: 0}}
                      >

                        {filteredAutomations.length === 0 ? inactiveLoanAutomations.length > 0 && inactiveLoanAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                          // <div key={loanAutomation.id + `${i}`} style={{marginBottom: '16px'}}>

                          <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                            ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                            handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                            offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                            onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                          />                      
                          // </div>
                        ))
                        :
                          filteredAutomations.slice().sort((a, b) => new Date(b?.created) - new Date(a?.created)).map((loanAutomation, i) => (
                            // <div key={loanAutomation.id + `${i}`} style={{marginBottom: '16px'}}>

                            <AutomationRuleCard key={loanAutomation.id} id={loanAutomation?.id} ruleName={loanAutomation?.rule_name} 
                              ruleStatus={loanAutomation?.rule_status} dateCreated={loanAutomation?.created} 
                              handleEditExistingRule={handleEditExistingRule} loanType={loanAutomation?.loan_type} 
                              offeredInterest={loanAutomation?.offered_interest} automateFund={loanAutomation?.automate_fund}
                              onHold={loanAutomation?.on_hold} refreshRules={refreshRules}
                            />                      
                            // </div>
                          ))
                        }
                      </FlipMove>
                    }
                  </div>              
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      
        {/* Automation Main Page (Zero State) */}
        {!editing && loanAutomations.length === 0 && !editExistingRule && (
          // <div className='container dashboard-content'>
          <div className='container-automation '>
            <img src={BlankFrame} alt="Image" />
            <p className="text" style={{marginTop: 40, marginBottom: 32}} >You dont have any rules setup in your account</p>
            


              {/* <div className='button-container'> */}
              <button disabled={!canCreateAutomationRule} onClick={ () => handleCreateAutomationRule() } type='button' className="button" >
                {/* <span>&times;</span> */}
                <p style={{margin: 'auto'}} >Create Automation Rule</p> 
              </button>
              {/* </div> */}

            
          </div>
        )}

        {/* Editing View */}
        {(editing || editExistingRule) && (
          <div className='container-editing-automation' style={{overflowX: 'hidden', overflowY:'auto'}} >
            <div style={{marginBottom: '40px'}}>
              <p style={{marginBottom: '16px'}}>
                <span className='star'>*</span>
                Rule Name</p>
              <input
                className={errors["rule_name"] ? 'input-error' : 'input'}
                // style={{width: '240px', }}
                type={'text'}
                id={'Name'}
                value={ruleName}
                name={'name'}
                placeholder={'Add a name for you rule.'}
                onChange={(e) => {
                  // console.log('value', e.target.value)
                  setRuleName(e.target.value)
                }}
                onFocus={() => {
                  if (errors["rule_name"]) setErrors([])
                }}
                // disabled={disabled}
              />
              <div className={errors["rule_name"] ? "error-message" : "d-none"}>* Required field</div>
            </div>

            <div style={{marginBottom: '48px'}}>
              <p style={{marginBottom: '33px'}} >Loan Type</p>
              <div style={{display: 'flex', flexDirection: 'row', margin: 0, overflowX: 'scroll', }} className='pb-3 pb-sm-0'>

                {/* {LoanTypesVisible.map((value, i) => (
                  <button key={value} type='button' className={selectedLoanType === value ? "button-loan-type-selected" : "button-loan-type"} onClick={() => setSelectedLoanType(LoanTypes[i])} >
                    <p style={{margin: 'auto'}} >{value}</p> 
                  </button>
                ))} */}
                {/* <button type='button' className={selectedLoanType === LoanTypes[0] ? "button-loan-type-selected" : "button-loan-type"} onClick={() => setSelectedLoanType(LoanTypes[0])} >
                  <p style={{margin: 'auto'}} >{LoanTypesVisible[0]}</p> 
                </button> */}
                <button type='button' className={selectedLoanType === LoanTypes[1] ? "button-loan-type-selected" : "button-loan-type"} 
                  onClick={() => {
                    setOfferInterest(0.03);
                    setSelectedLoanType(LoanTypes[1]);
                  }}
                >
                  <p style={{margin: 'auto'}} >{LoanTypesVisible[1]}</p> 
                </button>
                <button type='button' className={selectedLoanType === LoanTypes[2] ? "button-loan-type-selected" : "button-loan-type"} 
                  onClick={() => {
                    setOfferInterest(0.03);
                    setSelectedLoanType(LoanTypes[2]);
                  }}
                >
                  <p style={{margin: 'auto'}} >{LoanTypesVisible[2]}</p> 
                </button>


              </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p id='auto-fund-question-text' >Do you want to automatically deploy fund for this rule?</p>

              <div style={{lineHeight: '16px', margin: '0 0 24px 0',}}>
                <p id='auto-fund-text'>By Selecting “Yes:, Once the borrower  accept your offer we will automatically
                transfer the fund to his account you must have an enough balance at the
                time of the borrower accepotance.</p> 
              </div>

              <div style={{display: 'flex', flexDirection: 'row'}}>

                {/* {acceptAutoDeployFund.map(value => ( */}
                  <button type='button' className={acceptedAutoDeployFund ? "button-loan-type-selected2" : "button-loan-type2"} onClick={() => setAcceptedAutoDeployFund(true)} >
                    {/* <span>&times;</span> */}
                    <p style={{margin: 'auto'}} >Yes</p> 
                  </button>

                  <button type='button' className={!acceptedAutoDeployFund ? "button-loan-type-selected2" : "button-loan-type2"} onClick={() => setAcceptedAutoDeployFund(false)} >
                    {/* <span>&times;</span> */}
                    <p style={{margin: 'auto'}} >No</p> 
                  </button>
              </div>


            </div>

            <div style={{}} className='automation-condition-container'>
              {conditions && conditions.length > 0 && conditions.map((condition, index) => {
                return (

                  <AutomationCondition key={condition.id} 
                    id={condition.id} 
                    alreadyExistedConditionOption ={conditions[index].conditionOption} 
                    alreadyExistedOptions ={conditions[index].options} 
                    conditionNum={condition.num} 
                    handleClick={handleAddCondition} 
                    conditionOptions={conditionOptions} 
                    handleSelectChange={handleSelectChange} 
                    handleRemoveCondition={handleRemoveCondition} 
                    handleChangeOptions={handleChangeOptions}
                    selectedConditionOptions={selectedConditionOptions} 
                    selectedLoanType={selectedLoanType}
                  />
                )
              })}
              {/* {editExistingRule && editExistingRule.length > 0 && editExistingRule.map((rule, index) => {
                return (

                  <AutomationCondition key={index} conditionNum={rule.num} handleClick={handleAddCondition} conditionOptions={conditionOptions} handleSelectChange={handleSelectChange} handleRemoveCondition={handleRemoveCondition} handleChangeOptions={handleChangeOptions} />
                )
              })} */}
              {showSalusTOS && (
                <div style={{lineHeight: '16px', margin: '24px 0 0 0',}}>
                  <p id='auto-fund-text'>By selecting Salus Score in Automation, you agree to and accept our partner {''}
                    Salus's <a href="https://www.salusfintech.com/salus-score-terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                  </p> 
                </div>
              )}
              {initialOptions.length !== conditions.length ? (

                <div style={{ display: 'flex', marginTop: '24px'}}>
                  <button type='button' className='button-submit' onClick={handleAddCondition}  >
                    {/* <span>&times;</span> */}
                    <p style={{margin: 'auto'}} >Add Condition</p>
                  </button>
                </div>
              )
                : null
              }
            </div>

            <div style={{marginTop: '72px', width: 'fit-content'}}>
              <p style={{color: '#4B4B4B', fontSize: '24px', fontWeight: 700, fontFamily: 'Poppins'}}>Interest Rate</p>
              {/* <p style={{color: '#000', fontSize: '15px', fontWeight: 400, fontFamily: 'Poppins'}}>Expected Earning (fees)</p> */}

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '-10px'}}>
                <p style={{color: '#000', fontSize: '25px', fontWeight: 400, fontFamily: 'Poppins', lineHeight: 'normal'}}>{Math.trunc(offerInterest * 100)}%</p>
                <p style={{color: '#787878', fontSize: '13px', fontWeight: 400, fontFamily: 'Poppins', lineHeight: 'normal'}}>Annual Interest Rate {Math.trunc(offerInterest * 100)}%</p>
              </div>

              <div>
                {/* <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="slider-thumb"
                    trackClassName="example-track"
                    min={0}
                    max={9}
                    invert
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                /> */}
                <div style={{width: '319px',}}>

                  {/* <LNRangeSlider
                    min={3}
                    max={200}
                    value={1}
                    change_func={(new_val) => handleSliderChange(new_val)}
                    // className={'w-100'}
                    
                  /> */}
                  <div className='CustomSlider-track-test' style={{marginLeft: '10px', alignItems: 'center', justifyContent: 'center', paddingLeft: '6px'}}>

                    <Slider
                      slotProps={{
                        root: { className: 'CustomSlider' },
                        rail: { className: 'CustomSlider-rail' },
                        track: { className: 'CustomSlider-track' },
                        thumb: { className: 'CustomSlider-thumb'},
                      }}
                      // slots={{ valueLabel: SliderValueLabel }}
                      value={Math.trunc(offerInterest * 100)}
                      onChange={(e, value) => handleSliderChange(value)}
                      min={3}
                      max={selectedLoanType === LoanTypes[1] ? 200 : 25}
                      defaultValue={3}
                      style={{}}
                    />
                    <Styles />
                  </div>
                  {/* <CustomSlider min={1} max={10}  /> */}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',}}>
                  <p style={{color: '#787878', fontSize: '13px', fontWeight: 400, fontFamily: 'Poppins', lineHeight: 'normal'}}>3%</p>
                  <p style={{color: '#787878', fontSize: '13px', fontWeight: 400, fontFamily: 'Poppins', lineHeight: 'normal'}}>{selectedLoanType === LoanTypes[1] ? '200%' : '25%'}</p>
                </div>
              </div>

            </div>

            <div style={{width: '795px', height: '1px', background: '#CCC', marginTop: '45px'}} />

            <div style={{display: 'flex', flexDirection: 'row', marginTop: '32px'}}>

                <button type='button' className={"button-loan-type2"} onClick={handleCancelRules} >
                  {/* <span>&times;</span> */}
                  <p style={{margin: 'auto'}} >Cancel</p> 
                </button>

                <button type='button' className={"button-loan-type-selected2"} onClick={handleSaveRules} >
                  {/* <span>&times;</span> */}
                  <p style={{margin: 'auto'}} >Save</p> 
                </button>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AutomationPage

const cyan = {
  50: '#E9F8FC',
  100: '#BDEBF4',
  200: '#99D8E5',
  300: '#66BACC',
  400: '#1F94AD',
  500: '#0D5463',
  600: '#094855',
  700: '#063C47',
  800: '#043039',
  900: '#022127',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
function SliderValueLabel({ children }) {
  return <span className="valueLabel">{children}</span>;
}
function Styles() {
  // const isDarkMode = useIsDarkMode();
  return (
    <style>{`
    .CustomSlider {
      margin-top: -4px;
      // margin-left: 2px;
      // color: ;
      margin-left: -7px;
      height: 4px;
      width: 280px;
      padding: 16px 0;
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      touch-action: none;
      align-self: center;
      -webkit-tap-highlight-color: transparent;
    }

    .CustomSlider.${sliderClasses.disabled} { 
      pointer-events: none;
      cursor: default;
      color: ${grey[300]};
      opacity: 0.4;
      outline: none;
    }

    .CustomSlider-rail {
      display: block;
      position: absolute;
      // margin-left: -6px;
      width: 210px;
      height: 24px;
      // border: 1px solid #D8D8D8;
      // border-radius: 100px;
      // background-color: #000;
      // opacity: 0.3;
      
    }

    .CustomSlider-track {
      display: block;
      position: absolute;
      width: 100px;
      height: 24px;
      // margin-left: -10px;
      // padding-right: 8px;
      // padding-left: -60px;
      // margin-left: -6px;
      border-radius: 30px;
      background-color: #BFC6FF;
    }

    .CustomSlider-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 24px;
      height: 24px;
      // margin-right: -60px;
      margin-left: -12px;
      box-sizing: border-box;
      border-radius: 100px;
      outline: 0;
      background-color: #5D6AFF;
      transition-property: box-shadow, transform;
      transition-timing-function: ease;
      transition-duration: 120ms;
      transform-origin: center;
    }

    // .CustomSlider-thumb:hover {
    //   box-shadow: 0 0 0 6px ${alpha(cyan[200], 0.3)};
    // }

    // .CustomSlider-thumb.${sliderClasses.focusVisible} {
    //   box-shadow: 0 0 0 8px ${alpha(cyan[200], 0.5)};
    //   outline: none;
    // }

    // .CustomSlider-thumb.${sliderClasses.active} {
    //   box-shadow: 0 0 0 8px ${alpha(cyan[200], 0.5)};
    //   outline: none;
    //   transform: scale(1.2);
    // }

    // .CustomSlider-thumb.${sliderClasses.disabled} {
    //   background-color: ${grey[300]};
    // }
    `}</style>
  );
}