import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFeedData, fetchFeedNext } from "../../redux";
import moment from "moment";

// Components
import LNAvatar from "../common/LNAvatar/LNAvatar";
import MobileNav from "../mobileNav/mobileNav";

// Images
import emptyFeed from "../../img/static/feed_empty.svg";
import FeedBarLoadingBlock from "../../img/spinners/blocks/feed bar.svg";
import ShowMore from "../common/showMore/showMore";

// CSS
import "./feedPage.css";

function FeedPage(props) {
	const { loading, feed, paginate, next } = useSelector((state) => state.feed);
	const dispatch = useDispatch();

	// When Mount
	useEffect(() => {
		setTimeout(() => {
			dispatch(fetchFeedData());
		}, 1000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Helper Methods
	const isMoreThanOneDayAgo = (item) => {
		const a = moment();
		const b = moment(item.created);
		const diff = a.diff(b, "days");

		if (diff > 0) return false;
		return true;
	};

	return (
		<div className="feed-page">
			<div className="dashboard-wrapper">
				{/* Mobile Nav */}
				<MobileNav title="Feed" />

				<div className="dashboard-content">
					<div className="page-title d-none d-sm-block">Feed</div>

					<div className="mt-4">
						{/* Loading */}
						{loading && (
							<div className="d-flex flex-column">
								<span className="my-4">
									<img src={FeedBarLoadingBlock} alt="Loading..." />
								</span>
							</div>
						)}

						{/* Zero state */}
						{!loading && feed.results?.length === 0 && (
							<div className="d-flex flex-column">
								<span className="my-4">Nothing here! Check back later for updates.</span>
								<img src={emptyFeed} alt="Empty feed" />
							</div>
						)}

						{/* Feed Cards */}
						{!loading && feed.results?.length !== 0 && (
							<div>
								{feed.results.map((item) => (
									<div key={item.id} className="single-feed-card card p-3 mb-3">
										<div className="d-flex">
											<div className="mr-3">
												<LNAvatar
													data={[{ icon: item.lender.avatar, color: 4 }]}
													small={true}
												/>
											</div>

											<div className="flex-grow-1">
												<div className="d-flex">
													<div className="font-weight-bold ">
														<span>{item.lender.initial}</span> invested $
														<span>{Math.floor(item.loan.loan_amount)}</span> for{" "}
														<span>{item.loan.payment_terms}</span> month
													</div>

													<div className="ml-auto">
														{isMoreThanOneDayAgo(item) && (
															<h6>
																<span className="badge badge-secondary">New</span>
															</h6>
														)}
													</div>
												</div>
												<div className="d-flex mt-3">
													<div className="mr-4">
														<div className="small">Interest rate:</div>
														<div className="font-weight-bold">
															{parseInt(item.loan.interest_rate * 100)}%
														</div>
													</div>
													<div>
														<div className="small">Expected earning:</div>
														<div className="font-weight-bold">{item.loan.loan_return}</div>
													</div>
												</div>

												<div className="d-flex">
													<div className="ml-auto text-muted small">
														{moment(item.created).fromNow()}
													</div>
												</div>
											</div>
										</div>
									</div>
								))}

								{/* Show more */}
								{next !== null && (
									<div style={{ maxWidth: 400 }}>
										<ShowMore
											loading={paginate.loading}
											handleClick={() => dispatch(fetchFeedNext())}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeedPage;
