import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserData } from "../../redux";

// Import Services
import userService from "../../services/userService";
import fundingService from "../../services/fundingService";

// Import Utils
import { handleBalanceFormat } from "../../utils/handleBalanceFormat";

// Import Bootstrap Components
import { Modal } from "react-bootstrap";

// Import Components
import LNInput from "../common/LNInput/LNInput";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";
import BankProviders from "../bankProviders/bankProviders";
import MobileNav from "../mobileNav/mobileNav";

// Import Images
import ZeroStateImage from "../../img/static/bank-transfer-zero-state.svg";
import AddIcon from "../../img/icons/blue/add-icon.svg";
import BankTransferLoadingBlock from "../../img/spinners/blocks/bank transfer page.svg";
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';

// Import CSS
import "./bankTransferPage.css";
import { fetchTransactionsHistory } from "../../services/bankService";
import ZeroStateTransactionsHistory from '../../img/assets/zero-state-transactions-history.svg'
import TransactionsHistoryTags from "./TransactionsHistoryTags";

class BankTransferPage extends Component {
	state = {
		loading: true,
		modal: {
			fastlinkSuccess: {
				visible: false,
			},
			addingFunds: {
				visible: false,
			},
			transferringFunds: {
				visible: false,
			},
			transferSuccess: {
				visible: false,
			},
		},
		data: {
			transfer_amount: "",
			add_amount: "",
		},
		showProviders: false,
		balance: 0,
		fundingResources: null,
		primaryFundingResource: null,
		transferMessage: null,
		hasBanks: false,
		error: {
			error: false,
			message: null,
		},
		transactionsHistoryLoading: true,
		transactionsHistory: [],
		transactionsHistoryPending: [],
		transactionsHistoryProcessed: [],
		transactionsHistoryFailed: [],
		transactionsHistoryNext: null,
		tabValue: 1
	};

	// Get User Balance
	async getUserBalance() {
		try {
			const { data } = await userService.getUserBalance();
			this.setState({ balance: data?.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response?.data?.detail || "An error occurred.",
			};
			this.setState({ error });
		}
	}

	// Get User Funding Resources If Has Dwolla Customer Flag
	async getUserFundingResourcesIfHasDwollaCustomerFlag() {
		if (!this.props.userData.user.is_dwolla_customer) return;

		try {
			const { data } = await fundingService.getUserFundingResources();
			const hasBanks = data.results?.length > 0 ? true : false;
			console.log('hasBanks ', hasBanks)
			this.setState({ hasBanks, fundingResources: data.results });

			const primaryFundingResource = data.results.find((resource) => resource.is_primary);
			this.setState({ primaryFundingResource });

			// if (!primaryFundingResource) this.populateFavouriteProviders();
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.detail,
			};
			this.setState({ error });
		}
	}

	// Handle Loading
	handleLoading() {
		this.setState({ loading: false });
	}

	// When Mount
	async componentDidMount() {
		setTimeout(async() => {
			// Get user data
		await this.props.fetchUserData();

		// Get User balance
		await this.getUserBalance();

		// Get User funding resources if has dwolla customer flag
		await this.getUserFundingResourcesIfHasDwollaCustomerFlag();

		// Handle loading
		this.handleLoading();

		try {

			await fetchTransactionsHistory().then((res) => {
				console.log('fetchTransactionsHistory_res ', res);
				if (res.data?.results) {
					// this.setState({ transactionsHistory: res.data.results, transactionsHistoryNext: res.data?.next, transactionsHistoryLoading: false });
					let transactionsHistory = [];
					let transactionsHistoryPending = [];
					let transactionsHistoryProcessed = [];
					let transactionsHistoryFailed = [];

					res.data.results?.length > 0 && res.data.results.flatMap((transactionHistory, i) => {
						transactionHistory?.transactions?.map((transaction, j) => {

							// this.setState({ transactionsHistory: [...this.state.transactionsHistory, {...transaction, date: transactionHistory?.date}] });
							transactionsHistory = [...transactionsHistory, {...transaction, date: transactionHistory?.date}]
							
							if (transaction?.transfer_status === 'pending') {
								transactionsHistoryPending = [...transactionsHistoryPending, {...transaction, date: transactionHistory?.date}]
								// this.setState({ transactionsHistoryPending: [...this.state.transactionsHistoryPending, {...transaction, date: transactionHistory?.date}] });
							}
							else if (transaction?.transfer_status === 'processed') {
								transactionsHistoryProcessed = [...transactionsHistoryProcessed, {...transaction, date: transactionHistory?.date}]
								// this.setState({ transactionsHistoryProcessed: [...this.state.transactionsHistoryProcessed, {...transaction, date: transactionHistory?.date}] });
							}
							else if (transaction?.transfer_status === 'failed') {
								transactionsHistoryFailed = [...transactionsHistoryFailed, {...transaction, date: transactionHistory?.date}]
								// this.setState({ transactionsHistoryFailed: [...this.state.transactionsHistoryFailed, {...transaction, date: transactionHistory?.date}] });
							}
						})
					})
					console.log('transactionsHistory__', transactionsHistory)
					this.setState({ 
						transactionsHistory: transactionsHistory,
						transactionsHistoryPending: transactionsHistoryPending,
						transactionsHistoryProcessed: transactionsHistoryProcessed,
						transactionsHistoryFailed: transactionsHistoryFailed,
						transactionsHistoryNext: res.data?.next,
						transactionsHistoryLoading: false
					});

				}
				
			})
		} catch (err) {
			console.log('fetchTransactionsHistory_err ', err)
			this.setState({ transactionsHistoryLoading: false })
		}
		}, 2000);
		
	}

	// Helper Methods
	handleOpenModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = true;
		this.setState({ modal });
	};

	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		if (name === "addingFunds" || name === "transferringFunds") {
			let data = { ...this.state.data };
			Object.keys(data).forEach((x) => (data[x] = null));
			this.setState({ data });
		}
		this.setState({ modal });
	};

	// Controlled change for add and transfer money value inputs
	handleChange = ({ currentTarget: input, keyCode }) => {
		// Numbers Only and decimals with 2 points max
		const regex = /^[0-9]+([.]{0,1})([0-9]){0,2}?$/g;

		if (input.value === "" || regex.test(input.value)) {
			// If number and transferring, max is balance
			if (input.value !== "" && input.name === "transfer_amount" && input.value > this.state.balance) {
				input.value = this.state.balance;
			}

			// Max is 10000
			if (input.value !== "" && input.value > 10000) {
				input.value = 10000;
			}
			const data = { ...this.state.data };
			data[input.name] = input.value;
			this.setState({ data });
		}
	};

	// Transfer amount input in transfer modal to primary bank
	async transferToBank() {
		try {
			const transfer = await fundingService.transferBalanceToBank(this.state.data.transfer_amount);
			this.setState({ transferMessage: transfer.data.status });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");

			// Refresh User balance
			const userBalance = await userService.getUserBalance();
			const error = {
				error: false,
			};

			this.setState({ error, balance: userBalance.data.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.message,
			};
			this.setState({ error });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");
		}
	}

	// Transfer amount input in add modal to Lenme Balance
	async transferToLenme() {
		try {
			const transfer = await fundingService.transferBalanceToLenme(this.state.data.add_amount);
			this.setState({ transferMessage: transfer.data.message });
			this.handleCloseModal("addingFunds");
			this.handleOpenModal("transferSuccess");

			// Refresh User balance
			const userBalance = await userService.getUserBalance();
			const error = {
				error: false,
			};

			this.setState({ error, balance: userBalance.data.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.message,
			};
			this.setState({ error });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");
		}
	}

	hideProviders = () => {
		this.setState({ showProviders: false });
	};

	renderTransactionsHistoryRow = (transactionsHistory) => {
		// console.log('renderTransactionsHistoryRow_transactionsHistory ', transactionsHistory)
		// console.log('renderTransactionsHistoryRow_transactionsHistoryProcessed ', this.state.transactionsHistoryProcessed)

		// return transactionsHistory.length > 0 && transactionsHistory.flatMap((transactionHistory, i) => {
		return transactionsHistory?.length > 0 && transactionsHistory.map((transaction, i) => {
			let evenNum = i % 2 === 0;
			let lastTransaction = i === transactionsHistory?.length - 1;
			
			return (
				<div style={{display: 'flex', flexDirection: 'row', background: !evenNum ? '#F7F9FB' : '#FFF', height: '49px', padding: '16px', borderRadius: lastTransaction && '0px 0px 10px 10px', }} >
					<div className="transactions-history-row" style={{flexBasis: '15%'}}>
						<TransactionsHistoryTags status={transaction?.transfer_status} />
						{/* {transaction?.transfer_status} */}
					</div>
					<div className="transactions-history-row" style={{flexBasis: '15%'}}>{transaction?.date}</div>
					<div className="transactions-history-row" style={{flexBasis: '30%'}}>{transaction?.transfer_source_dest}</div>
					<div className="transactions-history-row" style={{flexBasis: '25%'}}>
						<TransactionsHistoryTags type={transaction?.transfer_type} />
						{/* {transaction?.transfer_type} */}
					</div>
					{this.renderLoanAmount(transaction?.transfer_amount, transaction?.transfer_status)}
					{/* <div className="transactions-history-row" style={{flexBasis: '15%', ...loanAmountColor}}>${transaction?.transfer_amount}</div> */}
				</div>
			)
		})
		// })
		
	}
	renderLoanAmount = (transfer_amount, transfer_status) => {
		let loanAmountDecoration = {};
		let amountSign = '';
		if (transfer_amount > 0) amountSign = '+'
		else amountSign = '-'

		if (transfer_status === 'failed') {
			// Strike Through
			loanAmountDecoration = {textDecoration: "line-through"}
		}
		// let formattedTransferAmount = transfer_amount?.toLocaleString(undefined, {minimumFractionDigits: 2}); // Output: "1,850.00"
		let formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// Math.abs to get the negative sign removed
		let formattedTransferAmount = formatter.format(Math.abs(transfer_amount));

		return (
			<div 
				className="transactions-history-row" 
				style={{flexBasis: '15%', color: amountSign === '+' ? '#089C8E' : '#000000' , ...loanAmountDecoration}}>
					{amountSign}${formattedTransferAmount}
			</div>
		)
	}

	renderTransactionsHistoryRowHeader = () => {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', background:'#EBEFF5', height: '3.313rem', alignItems: 'center',
				borderRadius: '10px 10px 0px 0px',
				padding: '16px',
				// maxWidth: '95vw'
				width: '50.5rem',
				whiteSpace: 'nowrap', 
				}}>
				<div className="transactions-history-row-header" style={{flexBasis: '15%'}}>Status</div>	
				<div className="transactions-history-row-header" style={{flexBasis: '15%'}}>Date</div>	
				<div className="transactions-history-row-header" style={{flexBasis: '30%'}}>From/To</div>	
				<div className="transactions-history-row-header" style={{flexBasis: '25%'}}>Type</div>	
				<div className="transactions-history-row-header" style={{flexBasis: '15%'}}>Loan Amount</div>	
			</div>
		)
	}

	getNestedArrayLengths = (arr) => {
		if (!arr?.length > 0) return 0;
		let totalLength = 0;
		for (let i = 0; i < arr?.length; i++) {
			totalLength += arr[i].transactions?.length;
		}
		console.log('totalLength ', totalLength)
		return totalLength;
	}

	render() {
		const {
			loading,
			showProviders,
			modal,
			balance,
			fundingResources,
			primaryFundingResource,
			data,
			transferMessage,
			hasBanks,
			error,
		} = this.state;
		const { userData } = this.props;
		const { user } = userData;

		// Check if loading
		if (userData.loading || loading) {
			return (
				<div className="bank-transfer-wrapper">
					<div className="bank-transfer-content">
						<img src={BankTransferLoadingBlock} alt="Loading..." style={{ width: "100%", maxWidth: 500 }} />
					</div>
				</div>
			);
		}

		// Render
		return (
			<div className="bank-transfer-wrapper">
				{/* Mobile Nav */}
				<MobileNav title="Bank Transfer" />

				<div className="bank-transfer-content">
					{/* hasBank */}
					{hasBanks && !showProviders && (
						<>
							{/* <div className="bank-transfer-title d-none d-sm-block">Transaction History</div> */}

							<div style={{display: 'flex', flexDirection: 'row', backgroundColor: '', alignItems: 'flex-start',}}>
								<div>
									<div className="bank-transfer-subtitle ">Available Lenme Cash</div>
									<div className="d-flex align-items-baseline bank-transfer-balance-wrapper">
										$<div className="bank-transfer-balance">{handleBalanceFormat(balance)}</div>
									</div>
								</div>

								<div style={{ width: '1px', height: '62px', background: '#E4E4E4', marginLeft: '1.5rem', marginRight: '1.5rem' }}/>

								<div style={{ display: 'flex', flexDirection: 'row', flex: 1, }}>
									{fundingResources.map((resource) => {
										console.log('fundingResources ', fundingResources)
										return (
											<div key={resource.id} style={{display: 'flex', flexDirection: 'row', flex: 1}}>
													
												<div className='d-flex flex-column' >
													<div className="">
														{resource.account_type && (
															<div className="bank-transfer-subtitle">
																{resource.account_type.charAt(0).toUpperCase() +
																	resource.account_type.slice(1)}{" "}
																- {resource.id}
															</div>
														)}
														<div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'baseline'}}>

															<div className="bank-transfer-subtitle" style={{marginTop: '12px', fontWeight: 400}}>
																{resource.bank_name}・
															</div>
															<div className="funding-resource-card-status" style={{color: resource.status === 'verified' && '#01CD9D', fontSize: '15px', fontWeight: 600, fontStyle: 'normal'}}>
																{resource.status.charAt(0).toUpperCase() +
																	resource.status.slice(1)}
															</div>
															{/* {resource.is_primary && (
																<div className="primary-bank-text">Primary</div>
															)} */}
														</div>
														
													</div>
												</div>
												{/* <div className='d-flex flex-column mr-1'>
													<div className="funding-resource-card-status">
														{resource.status.charAt(0).toUpperCase() +
															resource.status.slice(1)}
													</div>
													{resource.is_primary && (
														<div className="primary-bank-text">Primary</div>
													)}
												</div> */}

											</div>
										);
									})}
								</div>
							</div>
							{/* <hr className="w-100" /> */}
							<div className="" style={{display: 'flex', flexDirection: 'column', marginTop: '24px'}}  >
								<div className="" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >

									<div className="" style={{ marginRight: '20px', height: '42px',}}>
										<div
											className="btn btn-outline-secondary"
											style={{fontSize: '14px', width: '160px', height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', 
											border: '1px solid #000',
											borderRadius: '5px'
											}}
											onClick={() => {
												this.handleOpenModal("transferringFunds");
											}}>
											Transfer to bank
										</div>
									</div>
									<div className="" style={{height: '42px', }}>
										<div
											className="btn btn-black btn-block "
											style={{fontSize: '14px', width: '120px', height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px'}}
											onClick={() => {
												this.handleOpenModal("addingFunds");
											}}>
											Add money
										</div>
									</div>
								</div>
							</div>

							<div className="" style={{marginTop: '72px'}} >
								{/* <p style={{color: '#000', fontSize: '32px', fontWeight: 600, fontFamily: 'Poppins SemiBold'}}>Transaction History</p> */}
								<div className="bank-transfer-title" style={{marginBottom: '1.25rem'}}>Transaction History</div>
								<div>
									<Tabs onChange={(e, value) => {
										e.preventDefault();
										this.setState({tabValue: value})
										// setTabValue(value);
										// resetTabsStates();
										}} 
										style={{background:'', border: 'none', outline: 'none', width: '50.5rem', maxWidth: '94vw'}} defaultValue={1} >
										<TabsList style={{ borderBottomWidth: '1px', borderBottomColor: '#DCDDDE', borderBottomStyle: 'solid', outline: 'none', marginBottom: '19px', flex: 1, }} className="">
										<Tab style={{
											borderBottomColor: this.state.tabValue === 1 ? '#7652FF' : '',
											borderBottomWidth: this.state.tabValue === 1 ? '1px' : '',
											borderBottomStyle: this.state.tabValue === 1 ? 'solid' : '',
											// marginRight: '32px',
											paddingBottom: '9px',
											color: this.state.tabValue === 1 ? '#7652FF' : '#1A1A1A',
										}} 
										className="tab-item " value={1}>
											<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
											<div style={{marginRight: '5px'}}>All</div>
											{!this.state.transactionsHistoryLoading && this.state.transactionsHistory?.length > 0 && (

												<div style={{background: '#1A1A1A', borderRadius: '100px', width: '24px', height: '16px', textAlign: 'center',}}>
													<p style={{color:'#FFF', fontSize: '12px', fontWeight: 500}}>{this.state.transactionsHistory?.length}</p>
												</div>
											)}
											</div>
										</Tab>
										<Tab style={{
											borderBottomColor: this.state.tabValue === 2 ? '#7652FF' : '',
											borderBottomWidth: this.state.tabValue === 2 ? '1px' : '',
											borderBottomStyle: this.state.tabValue === 2 ? 'solid' : '',
											// marginRight: '32px',
											paddingBottom: '9px',
											color: this.state.tabValue === 2 ? '#7652FF' : '#1A1A1A',
										}} 
										className="tab-item " value={2}>
											Processed
										</Tab>
										<Tab style={{
											borderBottomColor: this.state.tabValue === 3 ? '#7652FF' : '',
											borderBottomWidth: this.state.tabValue === 3 ? '1px' : '',
											borderBottomStyle: this.state.tabValue === 3 ? 'solid' : '',
											color: this.state.tabValue === 3 ? '#7652FF' : '#1A1A1A',
											// marginRight: '32px',
											paddingBottom: '9px'
											}} 
											className="tab-item" value={3}
										>
											Pending
										</Tab>
										<Tab style={{
											borderBottomColor: this.state.tabValue === 4 ? '#7652FF' : '',
											borderBottomWidth: this.state.tabValue === 4 ? '1px' : '',
											borderBottomStyle: this.state.tabValue === 4 ? 'solid' : '',
											color: this.state.tabValue === 4 ? '#7652FF' : '#1A1A1A',
											paddingBottom: '9px',
											marginRight: '0px',
											}} 
											className="tab-item" value={4}
										>
											Failed
										</Tab>
										</TabsList>
										<TabPanel className="" value={1}>
											<div className="transactions-history-container" style={{padding: 0}}>
											   	{this.state.transactionsHistoryLoading ? null :
											   		(
														this.state.transactionsHistory?.length > 0 ? (
															<>
																{/* <div style={{padding: '16px'}}> */}
																{this.renderTransactionsHistoryRowHeader()}
																{/* </div> */}

																<div style={{}} className="transaction-history-row-container" >
																	{/* {this.state.transactionsHistory.length > 0 && this.state.transactionsHistory.map((transactionHistory, i) => {
																		let index = 0;
																		const evenNum = index % 2 === 0;
																		index += 1;
																		return this.renderTransactionsHistoryRow(transactionHistory)
																	})} */}
																	{this.renderTransactionsHistoryRow(this.state.transactionsHistory) }
																</div>
															</>
														)
															: 
														(

															<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '5.9375rem'}}>
																<img src={ZeroStateTransactionsHistory} alt="img" style={{marginBottom: '24px'}} />
																<div style={{color: '#7D7D7D', fontSize: '16px', fontWeight: 500}}>We couldn’t find any transaction history here.</div>
															</div>
														)
													)
												}										
											</div>
										</TabPanel>
										<TabPanel className="" value={2}>
											<div className="transactions-history-container" style={{padding: 0}}>
											   	{this.state.transactionsHistoryLoading ? null :
											   		(
														this.state.transactionsHistoryProcessed?.length > 0 ? (
															<>
																{/* <div style={{padding: '16px'}}> */}
																{this.renderTransactionsHistoryRowHeader()}
																{/* </div> */}

																<div style={{}} className="transaction-history-row-container" >
																	{/* {this.state.transactionsHistory.length > 0 && this.state.transactionsHistory.map((transactionHistory, i) => {
																		let index = 0;
																		const evenNum = index % 2 === 0;
																		index += 1;
																		return this.renderTransactionsHistoryRow(transactionHistory)
																	})} */}
																	{this.renderTransactionsHistoryRow(this.state.transactionsHistoryProcessed) }
																</div>
															</>
														)
															: 
														(

															<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '5.9375rem'}}>
																<img src={ZeroStateTransactionsHistory} alt="img" style={{marginBottom: '24px'}} />
																<div style={{color: '#7D7D7D', fontSize: '16px', fontWeight: 500}}>We couldn’t find any transaction history here.</div>
															</div>
														)
													)
												}
											</div>
										</TabPanel>
										<TabPanel className="" value={3}>
											<div className="transactions-history-container" style={{padding: 0}}>
											   	{this.state.transactionsHistoryLoading ? null :
											   		(
														this.state.transactionsHistoryPending?.length > 0 ? (
															<>
																{/* <div style={{padding: '16px'}}> */}
																{this.renderTransactionsHistoryRowHeader()}
																{/* </div> */}

																<div style={{}} className="transaction-history-row-container" >
																	{/* {this.state.transactionsHistory.length > 0 && this.state.transactionsHistory.map((transactionHistory, i) => {
																		let index = 0;
																		const evenNum = index % 2 === 0;
																		index += 1;
																		return this.renderTransactionsHistoryRow(transactionHistory)
																	})} */}
																	{this.renderTransactionsHistoryRow(this.state.transactionsHistoryPending) }
																</div>
															</>
														)
															: 
														(

															<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '5.9375rem'}}>
																<img src={ZeroStateTransactionsHistory} alt="img" style={{marginBottom: '24px'}} />
																<div style={{color: '#7D7D7D', fontSize: '16px', fontWeight: 500}}>We couldn’t find any transaction history here.</div>
															</div>
														)
													)
												}
											</div>
										</TabPanel>
										<TabPanel className="" value={4}>
											<div className="transactions-history-container" style={{padding: 0}}>
											   	{this.state.transactionsHistoryLoading ? null :
											   		(
														this.state.transactionsHistoryFailed?.length > 0 ? (
															<>
																{/* <div style={{padding: '16px'}}> */}
																{this.renderTransactionsHistoryRowHeader()}
																{/* </div> */}

																<div style={{}} className="transaction-history-row-container" >
																	{/* {this.state.transactionsHistory.length > 0 && this.state.transactionsHistory.map((transactionHistory, i) => {
																		let index = 0;
																		const evenNum = index % 2 === 0;
																		index += 1;
																		return this.renderTransactionsHistoryRow(transactionHistory)
																	})} */}
																	{this.renderTransactionsHistoryRow(this.state.transactionsHistoryFailed) }
																</div>
															</>
														)
															: 
														(

															<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '5.9375rem'}}>
																<img src={ZeroStateTransactionsHistory} alt="img" style={{marginBottom: '24px'}} />
																<div style={{color: '#7D7D7D', fontSize: '16px', fontWeight: 500}}>We couldn’t find any transaction history here.</div>
															</div>
														)
													)
												}
											</div>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</>
					)}

					{/* Zero state unverified */}
					{!hasBanks && !showProviders && (
						<div className="zero-state-section">
							<div className="zero-state-title">Link Bank Account.</div>

							<div className="d-flex justify-content-center justify-content-md-start">
								<div>
									<div>
										In order for funds to be transferred automatically in and out of your account,
										you will need to link a bank account.
									</div>
									<div>
										Your data is protected. We don't store your bank credentials and use 256-bit
										encryption.
									</div>
								</div>
							</div>

							<div className="image-container">
								<img src={ZeroStateImage} alt="Not Found" />
							</div>

							<div className="d-flex justify-content-center justify-content-md-start">
								<button
									className="btn btn-lg btn-black btn-block my-3"
									onClick={() => {
										this.setState({ showProviders: true });
									}}>
									Get started
								</button>
							</div>
						</div>
					)}

					{/* Providers */}
					{showProviders && <BankProviders hideProviders={this.hideProviders} />}

					{/* Modals */}
					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.fastlinkSuccess.visible}
						onHide={() => this.handleCloseModal("fastlinkSuccess")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("fastlinkSuccess");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">Great!</div>
								<div className="p-3">You have successfully linked your bank account!</div>
								<div className="d-flex w-100 justify-content-center p-3 flex-wrap">
									<ResponseIllustration type="success" />
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.handleCloseModal("fastlinkSuccess");
										}}>
										Get Started
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.transferringFunds.visible}
						onHide={() => this.handleCloseModal("transferringFunds")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("transferringFunds");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
									Transfer to Bank
								</div>
								<div className="p-3">Your current Lenme balance is ${handleBalanceFormat(balance)}</div>
								<div className="bank-transfer-popup d-flex w-100 p-3 m-3 flex-wrap">
									<div className="bank-transfer-popup-input">
										<LNInput
											placeholder={`$${handleBalanceFormat(balance)}`}
											name="transfer_amount"
											value={data.transfer_amount || ""}
											onChange={this.handleChange}
											removablePlaceholder={true}
											autoComplete="off"
										/>
									</div>
									<hr className="w-100 bank-transfer-popup-seperator" />
									{primaryFundingResource && (
										<div className="bank-transfer-popup-name mt-2">
											{primaryFundingResource.bank_name} - {primaryFundingResource.id}
										</div>
									)}
								</div>
								<div className="px-3 transfer-disclaimer">
									* The maximum amount per transaction is $10,000.
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-white btn-lg bank-transfer-button bank-transfer-cancel-button"
										onClick={() => {
											this.handleCloseModal("transferringFunds");
										}}>
										Cancel
									</div>
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.transferToBank();
										}}>
										{data.transfer_amount
											? `Transfer $${handleBalanceFormat(data.transfer_amount)}`
											: "Transfer to bank"}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.transferSuccess.visible}
						onHide={() => this.handleCloseModal("transferSuccess")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("transferSuccess");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
									{error.error ? "Oops!" : "Success!"}
								</div>
								<div className="p-3">{error.error ? error.message : transferMessage}</div>

								<div className="d-flex w-100 justify-content-center p-3 flex-wrap">
									{error.error ? (
										<ResponseIllustration type="error" />
									) : (
										<ResponseIllustration type="success" />
									)}
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.handleCloseModal("transferSuccess");
										}}>
										Done
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.addingFunds.visible}
						onHide={() => this.handleCloseModal("addingFunds")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("addingFunds");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">Add Money</div>
								<div className="p-3">Your current Lenme balance is ${handleBalanceFormat(balance)}</div>
								<div className="bank-transfer-popup d-flex w-100 p-3 m-3 flex-wrap">
									<div className="bank-transfer-popup-input">
										<LNInput
											placeholder="$0"
											name="add_amount"
											value={data.add_amount || ""}
											onChange={this.handleChange}
											removablePlaceholder={true}
											autoComplete="off"
										/>
									</div>
									<hr className="w-100 bank-transfer-popup-seperator" />
									{primaryFundingResource && (
										<div className="bank-transfer-popup-name mt-2">
											{primaryFundingResource.bank_name} - {primaryFundingResource.id}
										</div>
									)}
								</div>
								<div className="px-3 transfer-disclaimer">
									* The maximum amount per transaction is $10,000.
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-white btn-lg bank-transfer-button bank-transfer-cancel-button"
										onClick={() => {
											this.handleCloseModal("addingFunds");
										}}>
										Cancel
									</div>
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.transferToLenme();
										}}>
										{data.add_amount
											? `Add $${handleBalanceFormat(data.add_amount)}`
											: "Add to balance"}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchUserData: () => dispatch(fetchUserData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BankTransferPage);
