import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";
import ownerService from "../../../services/ownerService";

// Import Utils
import { getRegionList } from "../../../utils/handleRegions";
import { getCountryList } from "../../../utils/handleCountries";
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNSelect from "../../common/LNSelect/LNSelect";
import LNRadioInput from "../../common/LNRadioInput/LNRadioInput";
import LNDatePicker from "../../common/LNDatePicker/LNDatePicker";
import LNInputMask from "../../common/LNInputMask/LNInputMask";
import LNUpload from "../../common/LNUpload/LNUpload";
import OwnerCard from "../../ownerCard/ownerCard";
import LNError from "../../common/LNError/LNError";
import LNCheckBox from "../../common/LNCheckBox/LNCheckBox";

// Import Images
import AddIcon from "../../../img/icons/blue/add-icon.png";
import LeftArrowIcon from "../../../img/icons/blue/left-arrow.svg";

// Import CSS
import "./ownershipInfo.css";

class OwnershipInfo extends Component {
	state = {
		data: {},
		error: false,
		errorMessages: "",
		adding: false,
		owners: [],
		editing: false,
		ownerEditing: {},
		totalOwners: "",
		totalOwnershipPercentage: 0,
		verified: false,
		existingFileName: "",
		pressCount:0
	};

	// When Mount
	async componentDidMount() {
		setTimeout(async() => {
			// GET owners
			await this.populateOwners();

			//Set Data Initial State
			this.setDefaultOwnerState();
		}, 2000);
		
	}
	componentDidMount() {
		const loadCount = async () => {
		  try {
			const storedCount = await localStorage.getItem('pressCount');
			if (storedCount) {
			  console.log("parseInt(storedCount)",storedCount);
			  this.setState({ pressCount: parseInt(storedCount, 10) });
			}
		  } catch (error) {
			console.error('Error loading press count:', error);
		  }
		};
		loadCount();
	  }

	// Helper Methods
	populateOwners = async () => {
		console.log('populateOwners_ownership_info')
		try {
			const endpoint = `enterprise/v${process.env.REACT_APP_API_VERSION}/company_owners/`;
			const { data } = await http.get(endpoint);

			let totalOwnershipPercentage = parseInt(0, 10);

			for (let item of data.results) {
				let ownership_percentage = parseInt(item.ownership_percentage, 10);
				totalOwnershipPercentage += ownership_percentage;
			}

			this.setState({
				error: false,
				owners: data.results,
				totalOwners: data.results?.length,
				totalOwnershipPercentage: totalOwnershipPercentage,
			});
		} catch (ex) {
			console.log("Error: ", ex);

			this.setState({
				error: true,
				errorMessages: "Account not found. Please try again or contact support.",
			});
		}
	};

	setDefaultOwnerState = () => {
		const data = {
			first_name: "",
			middle_name: "",
			last_name: "",
			title: "",
			ownership_percentage: "",
			dob: "",
			ssn: "",
			street_address_1: "",
			street_address_2: "",
			city: "",
			state: "",
			zip_code: "",
			passport_number: "",
			passport_country: "",
			country: "US",
			file: "",
			identification_type: "",
		};

		this.setState({ data });
	};

	//Takes existing file ID and fetches file name and sets it into state
	getExistingFile = async (file_id) => {
		try {
			const endpoint = `enterprise/v${process.env.REACT_APP_API_VERSION}/enterprise_file_upload/${file_id}`;
			const { data } = await http.get(endpoint);
			return data;
		} catch (ex) {
			console.log(ex);
		}
	};

	editOwner = async (owner) => {
		// Get identification file details for owner to be edited
		const existingFile = await this.getExistingFile(owner.file);
		const ownerToBeEditedData = owner;
		ownerToBeEditedData.identification_type = existingFile.document_type;

		this.setState({
			editing: true,
			adding: true,
			ownerEditing: ownerToBeEditedData,
			existingFileName: existingFile.file_name,
		});
	};

	handleCertifyOwner = async () => {
		try {
			const certify_beneficial_ownership_data = {
				state: this.state.data.state,
				city: this.state.data.city,
				admin: {
					first_name: this.state.data.first_name,
					middle_name: this.state.data.middle_name,
					last_name: this.state.data.last_name,
				},
				zip_code: this.state.data.zip_code,
				street_address_1: this.state.data.street_address_1,
				street_address_2: this.state.data.street_address_2,
				name: this.state.data.title,
				type: "",
				registration_step: 0,
				is_verified: true,
				phone: "",
				website: "",
				ssn: this.state.data.ssn,
				legal_document_number: "",
				legal_document_file: 0,
				article_of_incorporation: 0,
			};

			const { data } = await ownerService.certifyOwner(certify_beneficial_ownership_data);
			console.log(data);
		} catch (ex) {
			console.log(ex);
		}
	};

	// Render
	render() {
		const { data, error, errorMessages, editing, ownerEditing, adding } = this.state;

		// Validations
		const ValidationSchema = Yup.object().shape({
			first_name: Yup.string().max(255, "Too Long!").required("Required field"),
			middle_name: Yup.string().max(255, "Too Long!"),
			last_name: Yup.string().max(255, "Too Long!").required("Required field"),
			title: Yup.string().max(10, "Too Long!").required("Required field"),
			ownership_percentage: Yup.number()
				.lessThan(101, "Your input must be between 25 and 100 percent.")
				.moreThan(24, "Your input must be between 25 and 100 percent.")
				.required("Required field"),
			dob: Yup.string().test(
				"dob",
				"You need to be atleast 18 years old.",
				(value) => moment().diff(moment(value), "years") >= 18
			),
			ssn: Yup.string().when(["country"], {
				is: "United States",
				then: Yup.string()
					.min(9, "SSN Should be 9 digits.")
					.max(9, "SSN Should be 9 digits.")
					.required("Required field"),
			}),
			street_address_1: Yup.string().max(255, "Too Long!").required("Required field"),
			street_address_2: Yup.string().max(255, "Too Long!"),
			city: Yup.string().max(255, "Too Long!").required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			passport_number: Yup.string()
				.min(6, "Passport should be atleast 6 characters.")
				.max(9, "Passport should be no more than 9 characters."),
			passport_country: Yup.string(),
			country: Yup.string().required("Required field"),
		});

		// Render
		return (
			<div className="signup-process-form ownership-info">
				{adding && (
					<>
						<div
							className="btn-link d-flex align-items-center mb-4 pointer"
							onClick={() => {
								this.props.setDotsVisibility(true);
								this.setState({
									adding: false,
									editing: false,
								});
							}}>
							<img src={LeftArrowIcon} alt="<" />
							<span className="ml-3">Back to ownership information</span>
						</div>

						{/* header */}
						<div className="form-header mb-4">
							<div className="main-form-label">
								{editing ? "Edit Owner Information" : "Add a new Owner"}
							</div>
						</div>

						{/* Form */}
						<Formik
							initialValues={editing ? ownerEditing : data}
							validationSchema={ValidationSchema}
							onSubmit={async (values, { setSubmitting, setErrors }) => {
								setSubmitting(true);
								const { REACT_APP_API_VERSION } = process.env;
								const postData = {
									first_name: values.first_name,
									middle_name: values.middle_name,
									last_name: values.last_name,
									title: values.title,
									ownership_percentage: values.ownership_percentage,
									dob: values.dob,
									ssn: values.ssn,
									street_address_1: values.street_address_1,
									street_address_2: values.street_address_2,
									city: values.city,
									state: values.state,
									zip_code: values.zip_code,
									passport_number: values.passport_number,
									passport_country: values.passport_country,
									country: values.country,
									file: values.file,
								};

								Object.keys(postData).forEach((key) => {
									if (!postData[key]) delete postData[key];
								});

								if (editing) {
									try {
										const endpoint = `enterprise/v${REACT_APP_API_VERSION}/company_owners/${ownerEditing.id}`;
										await http.put(endpoint, postData);

										this.setState({ error: false });
										setSubmitting(false);

										await this.populateOwners();
										this.setState({ adding: false });

										// Send GA event
										handleReactGAEvents("Sign_up_step_4");
									} catch (ex) {
										console.log("Error: ", ex);
										this.setState({
											error: true,
											errorMessages: ex.response.data,
										});

										setErrors(errorMessages);
										setSubmitting(false);
									}
								}

								if (!editing) {
									try {
										const endpoint = `enterprise/v${REACT_APP_API_VERSION}/company_owners/`;
										await http.post(endpoint, postData);
										await this.populateOwners();

										this.setState({ error: false });
										setSubmitting(false);
										this.setState({ adding: false });

										// Send GA event
										handleReactGAEvents("Sign_up_step_4");
									} catch (ex) {
										console.log("Error: ", ex);
										this.setState({
											error: true,
											errorMessages: ex.response.data,
										});

										setErrors(this.state.errorMessages);
										setSubmitting(false);
									}
								}
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								setSubmitting,
							}) => (
								<form onSubmit={handleSubmit} spellCheck={false}>
									<div className="inputs-wrapper">
										{error && <LNError text={errorMessages} />}

										<LNFloatingInput
											size="lg"
											type="text"
											name="first_name"
											value={values.first_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="First Name"
											error={
												touched.first_name && errors.first_name ? `* ${errors.first_name}` : ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											name="middle_name"
											optional={true}
											value={values.middle_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Middle Name"
											error={
												touched.middle_name && errors.middle_name
													? `* ${errors.middle_name}`
													: ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											name="last_name"
											value={values.last_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Last Name"
											error={touched.last_name && errors.last_name ? `* ${errors.last_name}` : ""}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											name="title"
											value={values.title}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Job title"
											error={touched.title && errors.title ? `* ${errors.title}` : ""}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											name="ownership_percentage"
											value={values.ownership_percentage}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Ownership %"
											error={
												touched.ownership_percentage && errors.ownership_percentage
													? `* ${errors.ownership_percentage}`
													: ""
											}
										/>

										<LNDatePicker
											size="lg"
											optional={false}
											name="dob"
											value={values.dob}
											placeholder="Date of birth"
											dateFormat="MM/dd/yyyy"
											onChange={(date) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))}
											error={touched.dob && errors.dob ? `* ${errors.dob}` : ""}
										/>

										{values.country === "US" && (
											<LNInputMask
												mask="999-99-9999"
												maskChar="X"
												value={values.ssn}
												onChange={(e) => {
													const value =
														e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
													setFieldValue("ssn", value);
												}}
												onBlur={handleBlur}
												size="lg"
												type="text"
												name="ssn"
												placeholder="Social security number"
												error={touched.ssn && errors.ssn ? `* ${errors.ssn}` : ""}
											/>
										)}

										<LNFloatingInput
											size="lg"
											type="text"
											name="street_address_1"
											value={values.street_address_1}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Address"
											error={
												touched.street_address_1 && errors.street_address_1
													? `* ${errors.street_address_1}`
													: ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											optional={true}
											name="street_address_2"
											value={values.street_address_2}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Address 2"
											error={
												touched.street_address_2 && errors.street_address_2
													? `* ${errors.street_address_2}`
													: ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											name="city"
											value={values.city}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="City"
											error={touched.city && errors.city ? `* ${errors.city}` : ""}
										/>

										<LNSelect
											name="state"
											size="lg"
											value={values.state}
											onChange={handleChange}
											onBlur={handleBlur}
											defaultOption={{ name: "State", value: "" }}
											options={getRegionList(values.country)}
											error={touched.state && errors.state ? `* ${errors.state}` : ""}
										/>

										<LNInputMask
											mask="99999"
											maskChar="X"
											value={values.zip_code}
											onChange={(e) => {
												const value = e.target.value.replace(/-/g, "").replace(/X/g, "");
												setFieldValue("zip_code", value);
											}}
											onBlur={handleBlur}
											size="lg"
											type="text"
											name="zip_code"
											placeholder="Zip Code"
											error={touched.zip_code && errors.zip_code ? `* ${errors.zip_code}` : ""}
										/>

										<LNSelect
											name="country"
											size="lg"
											value={values.country}
											onChange={handleChange}
											onBlur={handleBlur}
											defaultOption={{ name: "Country", value: "" }}
											options={getCountryList()}
											error={touched.country && errors.country ? `* ${errors.country}` : ""}
										/>

										{values.country === "US" && (
											<>
												<span className="text-muted d-block mb-3">ID Document</span>

												<div className="mb-2">
													<div className="mb-2">
														<LNRadioInput
															name="identification_type"
															label="ID"
															value="idCard"
															onChange={handleChange}
														/>
													</div>

													{values.identification_type === "idCard" && (
														<LNUpload
															document_type="idCard"
															change_function={(file_id) => {
																setFieldValue("file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
															existing_file={
																this.state.editing ? this.state.existingFileName : ""
															}
														/>
													)}

													<div className="mb-2">
														<LNRadioInput
															name="identification_type"
															label="Driver's License"
															value="license"
															onChange={handleChange}
														/>
													</div>

													{values.identification_type === "license" && (
														<LNUpload
															document_type="license"
															change_function={(file_id) => {
																setFieldValue("file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
															existing_file={
																this.state.editing ? this.state.existingFileName : ""
															}
														/>
													)}
												</div>
											</>
										)}
										{values.country !== "US" && (
											<>
												<span className="text-muted d-block mb-3">Passport</span>
												<LNUpload
													document_type="passport"
													change_function={(file_id) => {
														setFieldValue("file", file_id);
													}}
													set_loading={(state) => {
														setSubmitting(state);
													}}
													existing_file={
														this.state.editing ? this.state.existingFileName : ""
													}
												/>
												<LNInputMask
													mask="999999999"
													maskChar="X"
													value={values.passport_number}
													onChange={handleChange}
													onBlur={handleBlur}
													size="lg"
													type="text"
													name="passport_number"
													placeholder="Passport Number"
													error={
														touched.passport_number && errors.passport_number
															? `* ${errors.passport_number}`
															: ""
													}
												/>
												<LNSelect
													name="passport_country"
													size="lg"
													value={values.passport_country}
													onChange={handleChange}
													onBlur={handleBlur}
													defaultOption={{ name: "Passport Country", value: "" }}
													options={getCountryList()}
												/>
											</>
										)}
									</div>

									<div className="d-flex mt-5 buttons-wrapper">
										<div className="mr-3">
											<Link 
												onClick={async()=>{
													const newCount = this.state.pressCount + 1;
													this.setState({ pressCount: newCount });
													try {
													await localStorage.setItem('pressCount', newCount.toString());
														console.log('Press count saved to storage:', newCount);
													} catch (error) {
														console.error('Error saving press count:', error);
													}
												}}
												className="btn btn-white btn-lg btn-block" to="/">
												Save and Exit
											</Link>
										</div>

										<div className="flex-grow-1">
											<button
												type="submit"
												className="btn btn-primary btn-lg btn-block"
												disabled={isSubmitting}>
												Next
											</button>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</>
				)}
				{!this.state.adding && (
					<>
						{/* header */}
						<div className="form-header mb-5">
							<div className="main-form-label">Start lending with Lenme Enterprise</div>
							<div>Let's define the firm's ownership structure.</div>
						</div>
						<div className="inputs-wrapper">
							{this.state.totalOwners > 0 && (
								<div className="body-text d-flex pb-3">
									Total number of owners
									<div className="total-owners font-weight-bold pl-1">{this.state.totalOwners}</div>
								</div>
							)}
							{this.state.owners.map((owner) => {
								return (
									<OwnerCard
										key={owner.id}
										owner={owner}
										update_function={this.populateOwners}
										edit_function={() => this.editOwner(owner)}
									/>
								);
							})}
							{this.state.totalOwnershipPercentage < 75 && (
								<button
									className="btn btn-lg btn-outline-white btn-icon-text btn-block text-left"
									onClick={() => {
										//Resets Owner Data State
										this.setDefaultOwnerState();
										//Hides dots in Form view
										this.props.setDotsVisibility(false);
										//Shows Form view
										this.setState({ adding: true });
									}}>
									<img src={AddIcon} alt="+" />
									Add an owner
								</button>
							)}
							{this.state.totalOwnershipPercentage > 0 && (
								<div className="body-text d-flex pt-3">
									Total Ownership:
									<div className="total-owners font-weight-bold pl-1">
										{this.state.totalOwnershipPercentage}%
									</div>
								</div>
							)}
							<div className="mb-4 mt-3">
								<LNCheckBox
									label="I certify that the information provided is true and correct."
									name="keepMeLoggedIn"
									checked={this.state.verified}
									onChange={(e) => {
										this.setState({ verified: !this.state.verified });
									}}
									disabled={!this.state.owners?.length}
								/>
							</div>
						</div>
						<div className="d-flex mt-5 buttons-wrapper">
							<div className="mr-3">
								<Link 
									className="btn btn-white btn-lg btn-block" 
									to="/"
									onClick={async () => {
										const newCount = this.state.pressCount + 1;
										this.setState({ pressCount: newCount });
										try {
											await localStorage.setItem('pressCount', newCount.toString());
												console.log('Press count saved to storage:', newCount);
											} 
										catch (error) {
											console.error('Error saving press count:', error);
											}}
									} 
									>
									Save and Exit
								</Link>
							</div>
							<div className="flex-grow-1">
								<button
									type="submit"
									className="btn btn-primary btn-lg btn-block"
									onClick={() => {
										this.handleCertifyOwner();
										this.props.incrementfunction();
									}}
									disabled={!this.state.verified}>
									Next
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

// Prop Types
OwnershipInfo.propTypes = {
	setDotsVisibility: PropTypes.func.isRequired,
	incrementfunction: PropTypes.func.isRequired,
};

export default OwnershipInfo;
